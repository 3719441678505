import axios from "axios";

export interface RequestPostAuth {
  name: string;
  password: string;
}
export interface ResponsePostAuth {
  id: number;
  name: string;
  role: "admin" | "staff";
  createdAt: string;
}

export const LPPAXIOS = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_LOCALTEST}`
      : `${process.env.REACT_APP_ENDPOINT}`,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});
LPPAXIOS.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.message === "invalid_token") {
      localStorage.clear();
      window.location.reload();
    } else {
      throw error;
    }
  }
);

export const IMGUPLOAD = axios.create({
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});
