import {
  Button,
  Divider,
  Form,
  Input,
  Layout,
  notification,
  theme,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios from "axios";
import Editor from "../../../components/common/Editor/OnlyTextEditor";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import DatePicker, { RangePickerProps } from "antd/es/date-picker";
import dayjs, { Dayjs } from "dayjs";
import { HContent } from "../../../components/common/HContent";
import { useNavigate } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";

interface LINKTOOL {
  link: string;
}

interface GETUPLOADURL {
  path: string;
  url: string;
}

interface PARAGRAPH {
  text: string;
}
interface FILE64 {
  caption: string;
  stretched: boolean;
  withBackground: boolean;
  withBorder: boolean;
  file: {
    url: string;
    mimetype: string;
    origin: File;
  };
}
interface Block {
  id: string;
  type: "image" | "paragraph" | "linkTool";
  data: FILE64 | PARAGRAPH | LINKTOOL;
}
interface EditorSave {
  time: number;
  version: string;
  blocks: Block[];
}

const { Header } = Layout;
const { Title } = Typography;

export const CreatePage = () => {
  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [{ label: "7일", value: [dayjs(), dayjs().add(6, "d")] }];
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [days, setDays] = useState<[dayjs.Dayjs, dayjs.Dayjs]>(
    rangePresets[0].value
  );
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
  const [api, contextHolder] = notification.useNotification();

  async function uploadImgToS3(
    file: ArrayBuffer,
    type: string,
    url: string,
    path: string
  ) {
    const res = await axios.put(url, file, {
      headers: {
        "Content-Type": type,
      },
    });
    if (res.status === 200) {
      return path;
    }
    return "";
  }

  const handleUploadCompMultiFiles = async () => {
    if (uploadFileList.length === 0) return;
    try {
      const paths: string[] = [];
      for (let i = 0; i < uploadFileList.length; i++) {
        const fileBuffer = await uploadFileList[i].originFileObj?.arrayBuffer();
        const res = await LPPAXIOS.get<GETUPLOADURL[]>(
          `admin/files/upload?type=images&mimeType=${uploadFileList[i].type}&imageUploadTarget=challenge&num=1`
        );
        if (res.status === 200) {
          const path = await uploadImgToS3(
            fileBuffer!,
            uploadFileList[i].type!,
            res.data[0].url,
            res.data[0].path
          );
          paths.push(path);
        }
      }

      return paths;
    } catch (error) {
    } finally {
    }
  };

  const handleConvertEditorBlocks = async () => {
    if (!data) return;
    const convertArray: any[] = [];
    const { blocks } = data as EditorSave;
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].type === "image") {
        const file64 = blocks[i].data as FILE64;
        const arrayBuffer = await file64.file.origin.arrayBuffer();
        const res = await LPPAXIOS.get<GETUPLOADURL[]>(
          `admin/files/upload?type=images&mimeType=${file64.file.mimetype}&imageUploadTarget=challenge&num=1`
        );
        if (res.status === 200) {
          const path = await uploadImgToS3(
            arrayBuffer,
            file64.file.mimetype,
            res.data[0].url,
            res.data[0].path
          );
          convertArray.push({
            id: blocks[i].id,
            type: "image",
            data: {
              file: {
                url: path,
              },
            },
            caption: "",
            withBorder: true,
            stretched: false,
            withBackground: false,
          });
        }
      } else if (blocks[i].type === "paragraph") {
        const paragraph = blocks[i].data as PARAGRAPH;
        const text = paragraph.text
          .replaceAll("&nbsp;", "")
          .split(/(<br>)/)
          .filter(Boolean);
        text.forEach((entry, idx) => {
          convertArray.push({
            id: `${blocks[i].id}${idx}`,
            type: blocks[i].type,
            data: {
              text: entry,
            },
          });
        });
        // const text = paragraph.text
        //   .replaceAll("&nbsp;", "")
        //   .replaceAll("<br>", "");
        // convertArray.push({
        //   id: blocks[i].id,
        //   type: blocks[i].type,
        //   data: {
        //     text: text,
        //   },
        // });
      } else if (blocks[i].type === "linkTool") {
        const paragraph = blocks[i].data as LINKTOOL;

        const link = paragraph.link;
        convertArray.push({
          id: blocks[i].id,
          type: blocks[i].type,
          data: {
            link: link,
          },
        });
      }
    }
    return convertArray;
  };
  const handlePostChallenge = async (value: any) => {
    if (!data) {
      api.error({
        message: "챌린지 상세내용 등록은 필수입니다.",
        description: "상세내용을 작성하시기 바랍니다.",
      });
      return;
    }

    if (uploadFileList.length === 0) {
      api.error({
        message: "챌린지 썸네일 등록은 필수입니다.",
        description: "1개 이상의 이미지를 등록해주세요",
      });
      return;
    }
    if (days[0] === null || days[1] === null) {
      api.error({
        message: "챌린지 기간 등록은 필수입니다.",
        description: "챌린지 기간을 등록해주세요",
      });
      return;
    }
    try {
      setLoading(true);
      const images = (await handleUploadCompMultiFiles()) ?? [];
      const descriptionEditorData = (await handleConvertEditorBlocks()) ?? [];

      const formData = {
        topic: value.topic,
        content: descriptionEditorData,
        image: images[0],
        startDate: days[0].format("YYYY.MM.DD"),
        endDate: days[1].format("YYYY.MM.DD"),
      };
      // console.log(formData);
      // return;
      const res = await LPPAXIOS.post(`/admin/challenge`, formData);
      if (res.status === 200) {
        api.success({
          message: `챌린지 등록을 성공하였습니다.`,
        });
        setTimeout(() => navigate(LPPUrls.Admin.Challenge.Main.url()), 500);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: `챌린지 등록을 실패하였습니다.`,
          description: `${error.message}`,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  //   // Can not select days before today and today
  //   return current && current < dayjs().endOf("day");
  // };

  const handleOnChangeDaysRange = async (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  ) => {
    if (dates === null) return;
    if (dates[0] !== null && dates[1] !== null) {
      setDays([dates[0], dates[1]]);
    }
    return;
  };

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="drop-shadow-sm"
      >
        <Title>챌린지 등록</Title>
      </Header>
      <HContent>
        <Form onFinish={handlePostChallenge} style={{ width: 800 }}>
          <Form.Item label="챌린지 썸네일">
            <Upload
              fileList={uploadFileList}
              accept="image/*"
              listType="picture-card"
              showUploadList={{ showPreviewIcon: false }}
              customRequest={({ onSuccess }) => {
                const res = "Ok";
                setTimeout(() => {
                  onSuccess!(res);
                }, 600);
              }}
              onChange={({ fileList }) => {
                setUploadFileList(fileList);
              }}
            >
              {uploadFileList.length >= 1 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            label="챌린지 제목"
            name="topic"
            rules={[
              { required: true, message: "챌린지 제목 입력은 필수입니다." },
              {
                min: 8,
                message: "챌린지 제목은 최소 8글자 이상 입력해주시기 바랍니다.",
              },
            ]}
          >
            <Input showCount maxLength={100} onInput={preventSpaceBar} />
          </Form.Item>
          <Form.Item label="챌린지 기간">
            <DatePicker.RangePicker
              // disabledDate={disabledDate}
              defaultValue={rangePresets[0].value}
              presets={rangePresets}
              onCalendarChange={handleOnChangeDaysRange}
            />
          </Form.Item>

          <Divider />
          <Typography.Title level={4}>챌린지 상세 설명</Typography.Title>
          <div className="m-4 w-[100%] p-[5%] bg-[#f4f5f9]">
            <Editor data={data} setData={setData} />
          </div>
          <Form.Item>
            <div className="flex justify-center w-full">
              <Button type="primary" htmlType="submit">
                챌린지 등록
              </Button>
            </div>
          </Form.Item>
        </Form>
      </HContent>
    </>
  );
};
