import { Link, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Descriptions,
  Avatar,
  Row,
  Col,
  Result,
  Button,
  Space,
  Input,
  InputRef,
} from "antd";
import { LPPUrls } from "../../../LPPUrls";
import {
  EditOutlined,
  CheckSquareTwoTone,
  CloseSquareTwoTone,
} from "@ant-design/icons";
import { PointChart } from "./Component/PointChart";
import { useEffect, useRef, useState } from "react";
import axios, { AxiosError } from "axios";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { HContent } from "../../../components/common/HContent";
import { timeConverter } from "../../../utils/timeConverter";
import useNotification from "antd/es/notification/useNotification";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const { Header } = Layout;
const { Title } = Typography;

export interface USERDETAILTYPE {
  id: number;
  nickname: string;
  email: string;
  phone: string;
  type: string;
  profile: string;
  createdAt: string;
  point: number;
  movingDistance: number;
  co2Reduction: number;
  address: string;
  isStop: number;
  expectedExpiredAmount: number;
}

export const DetailPage = () => {
  const { id } = useParams();

  const inputRef = useRef<InputRef>(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<USERDETAILTYPE>({
    id: 0,
    nickname: "string",
    email: "string",
    phone: "string",
    type: "string",
    profile: "string",
    createdAt: "string",
    point: 0,
    movingDistance: 0,
    co2Reduction: 0,
    address: "",
    isStop: 0,
    expectedExpiredAmount: 0,
  });
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("My Sheet"); // sheet 이름이 My Sheet

    // sheet 데이터 설정
    worksheet.columns = [
      { header: "Id", key: "id", width: 10 },
      { header: "닉네임", key: "nickname", width: 32 },
      { header: "이메일", key: "email", width: 32 },
      { header: "전화번호", key: "phone", width: 20 },
      { header: "가입경로", key: "type", width: 15 },
      { header: "가입일", key: "createdAt", width: 20 },
      { header: "포인트", key: "point", width: 20 },
      { header: "이동거리", key: "moving_distance", width: 20 },
      { header: "탄소 감축량", key: "co2_reduction", width: 20 },
    ];

    worksheet.addRow({
      id: data.id,
      nickname: data.nickname,
      email: data.email,
      phone: data.phone,
      type: data.type,
      createdAt: data.createdAt,
      point: data.point,
      moving_distance: data.movingDistance,
      co2_reduction: data.co2Reduction,
    });

    // 다운로드
    const mimeType = {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);
    saveAs(blob, `${data.nickname} 회원상세.xlsx`);
  };

  //회원 정지 취소
  const handleUserBlockCancel = async () => {
    setLoading(true);

    LPPAXIOS.delete(`/admin/users/${id}`)
      .then(() => {
        setData((prev) => {
          return { ...prev, isStop: 1 };
        });
        api.success({
          message: "회원 활동 상태가 정상으로 변경되었습니다.",
        });
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          api.error({
            message: "잘못된 요청입니다.",
            description: `${error.response.status}Error`,
          });
        } else if (error.response.status === 404) {
          api.error({
            message: "잘못된 요청입니다.",
            description: `${error.response.status}Error`,
          });
        }
      })
      .finally(() => setLoading(false));
  };
  //회원 정지
  const handleUserBlock = () => {
    setLoading(true);
    // 1이면 정상, 0이면 정지됨.
    LPPAXIOS.post(`/admin/users/${id}`)
      .then(() => {
        setData((prev) => {
          return { ...prev, isStop: 0 };
        });
        api.success({
          message: "회원 활동 상태가 정지상태로 변경되었습니다.",
        });
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          api.error({
            message: "잘못된 요청입니다.",
            description: `${error.response.status}Error`,
          });
        } else if (error.response.status === 404) {
          api.error({
            message: "잘못된 요청입니다.",
            description: `${error.response.status}Error`,
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const handeChangeUserPoint = async () => {
    if (
      inputRef.current?.input?.value === null ||
      inputRef.current?.input?.value === undefined ||
      inputRef.current?.input?.value === ""
    ) {
      // Enter 입력이 되면 클릭 이벤트 실행
      api.error({
        message: "올바른 값을 입력하세요",
      });
      return;
    }
    setLoading(false);
    LPPAXIOS.put(`/admin/users/${id}`, {
      point: Number(inputRef.current?.input?.value),
    })
      .then(() => {
        api.success({
          message: "포인트가 변경되었습니다.",
        });
        setData((prev) => {
          return { ...prev, point: Number(inputRef.current?.input?.value) };
        });
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          api.error({
            message: "잘못된 요청입니다.",
            description: error.response.status,
          });
        } else if (error.response.status === 404) {
          api.error({
            message: "해당 요청을 수행할 수 없습니다.",
            description: error.response.status,
          });
        }
      })
      .finally(() => {
        setLoading(false);
        handleEditMode();
      });
  };

  const handleEditMode = () => {
    setEditMode((prev) => !prev);
  };
  const handleOnKeyPress = (e: any) => {
    if (e.key === "Enter") {
      if (
        e.currentTarget.value === null ||
        e.currentTarget.value === undefined ||
        e.currentTarget.value === ""
      ) {
        // Enter 입력이 되면 클릭 이벤트 실행
        api.error({
          message: "올바른 값을 입력하세요",
        });
      } else {
        handeChangeUserPoint();
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<USERDETAILTYPE>(`/admin/users/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          return setError(error);
        }
        return setError(error);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (error !== null) return <Result status={404} title={"404"} />;
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>회원관리</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Users.Main.url()}>회원관리</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>회원 상세페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <div className="flex items-center gap-3 p-2 bg-white border-2 rounded-md border-slate-600">
          <Avatar
            size={150}
            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.profile}`}
          />

          <Descriptions bordered>
            <Descriptions.Item label="이름" span={3}>
              {data?.nickname}
            </Descriptions.Item>
            <Descriptions.Item label="이메일" span={3}>
              {data?.email}
            </Descriptions.Item>
            <Descriptions.Item label="전화번호" span={2}>
              {data?.phone}
            </Descriptions.Item>

            <Descriptions.Item label="가입 경로">
              {data?.type}
            </Descriptions.Item>
            <Descriptions.Item label="가입일">
              {timeConverter(data?.createdAt ?? "")}
            </Descriptions.Item>
            <Descriptions.Item label="포인트" span={2}>
              {/* {data?.point.toLocaleString("Kr")} */}
              <span>
                {editMode === true ? (
                  <span>
                    <Input
                      ref={inputRef}
                      maxLength={10}
                      onInput={(e) =>
                        (e.currentTarget.value = e.currentTarget.value.replace(
                          /[^0-9]/g,
                          ""
                        ))
                      }
                      onKeyPress={handleOnKeyPress}
                      defaultValue={data.point}
                      size="small"
                    />
                    <CheckSquareTwoTone
                      style={{ fontSize: 16 }}
                      twoToneColor="#52c41a"
                      onClick={handeChangeUserPoint}
                    />
                  </span>
                ) : (
                  <span>{data.point.toLocaleString("Kr")}</span>
                )}
                {editMode === true ? (
                  <CloseSquareTwoTone
                    style={{ fontSize: 16 }}
                    twoToneColor="#eb2f96"
                    onClick={handleEditMode}
                  />
                ) : (
                  <EditOutlined
                    style={{ fontSize: 16 }}
                    onClick={handleEditMode}
                  />
                )}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="소멸 예정 포인트">
              {data?.expectedExpiredAmount?.toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="이동 거리">
              {data?.movingDistance.toLocaleString("Kr")}km
            </Descriptions.Item>
            <Descriptions.Item label="탄소 감축량">
              {data?.co2Reduction.toLocaleString("Kr")} kg/co2
            </Descriptions.Item>
            <Descriptions.Item label="회원 활동 상태">
              {data?.isStop === 0 ? (
                <Typography.Text type="danger" strong>
                  정지
                </Typography.Text>
              ) : (
                <Typography.Text type="success" strong>
                  정상
                </Typography.Text>
              )}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <Space className="justify-between w-full">
          <Space>
            <Button
              disabled={data.isStop === 0 ? true : false}
              onClick={handleUserBlock}
            >
              활동정지
            </Button>

            <Button
              disabled={data.isStop === 0 ? false : true}
              onClick={handleUserBlockCancel}
            >
              정지취소
            </Button>
          </Space>
          <Button onClick={handleExcel}>회원 정보 다운로드</Button>
        </Space>
        <Row gutter={[12, 12]}>
          <Col lg={16} md={16}></Col>
          <PointChart userId={Number(id)} />
        </Row>
      </HContent>
    </>
  );
};
