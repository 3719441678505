import {
  Button,
  Divider,
  Form,
  Input,
  Layout,
  notification,
  theme,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios from "axios";
import Editor from "../../../components/common/Editor/OnlyTextEditor";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";

interface GETUPLOADURL {
  path: string;
  url: string;
}
interface LINKTOOL {
  link: string;
}

interface PARAGRAPH {
  text: string;
}
interface FILE64 {
  caption: string;
  stretched: boolean;
  withBackground: boolean;
  withBorder: boolean;
  file: {
    url: string;
    mimetype: string;
    origin: File;
  };
}

const { Header, Content } = Layout;
const { Title } = Typography;

export const UpdatePage = () => {
  const location = useLocation();
  const { id } = useParams();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(location.state.content);
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
  const [api, contextHolder] = notification.useNotification();

  async function uploadImgToS3(
    file: ArrayBuffer,
    type: string,
    url: string,
    path: string
  ) {
    const res = await axios.put(url, file, {
      headers: {
        "Content-Type": type,
      },
    });
    if (res.status === 200) {
      return path;
    }
    return "";
  }

  const handleUploadCompMultiFiles = async () => {
    try {
      if (uploadFileList.length === 0) return;
      const paths: string[] = [];
      for (let i = 0; i < uploadFileList.length; i++) {
        if (uploadFileList[i].uid.includes("done file")) {
          paths.push(
            uploadFileList[i].url!.replace(
              `${process.env.REACT_APP_IMG_ENDPOINT}/`,
              ""
            )
          );
        } else {
          const fileBuffer = await uploadFileList[
            i
          ].originFileObj?.arrayBuffer();
          const res = await LPPAXIOS.get<GETUPLOADURL[]>(
            `admin/files/upload?type=images&mimeType=${uploadFileList[i].type}&imageUploadTarget=notice&num=1`
          );
          if (res.status === 200) {
            const path = await uploadImgToS3(
              fileBuffer!,
              uploadFileList[i].type!,
              res.data[0].url,
              res.data[0].path
            );
            paths.push(path);
          }
        }
      }

      return paths;
    } catch (error) {
    } finally {
    }
  };

  const handleConvertEditorBlocks = async () => {
    if (!data) return;

    const convertArray: any[] = [];
    const { blocks } = data;

    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].type === "image" && !blocks[i].id.includes("done file")) {
        const file64 = blocks[i].data as FILE64;
        const arrayBuffer = await file64.file.origin.arrayBuffer();
        const res = await LPPAXIOS.get<GETUPLOADURL[]>(
          `admin/files/upload?type=images&mimeType=${file64.file.mimetype}&imageUploadTarget=notice&num=1`
        );
        if (res.status === 200) {
          const path = await uploadImgToS3(
            arrayBuffer,
            file64.file.mimetype,
            res.data[0].url,
            res.data[0].path
          );
          convertArray.push({
            id: blocks[i].id,
            type: "image",
            data: {
              file: {
                url: path,
              },
            },
            caption: "",
            withBorder: true,
            stretched: false,
            withBackground: false,
          });
        }
      } else if (
        blocks[i].type === "image" &&
        blocks[i].id.includes("done file")
      ) {
        blocks[i].data.file.url = blocks[i].data.file.url.replace(
          `${process.env.REACT_APP_IMG_ENDPOINT}/`,
          ""
        );
        convertArray.push(blocks[i]);
      } else if (blocks[i].type === "paragraph") {
        const paragraph = blocks[i].data as PARAGRAPH;
        const text = paragraph.text
          .replaceAll("&nbsp;", "")
          .split(/(<br>)/)
          .filter(Boolean);

        text.forEach((entry, idx) => {
          convertArray.push({
            id: `${blocks[i].id}${idx}`,
            type: blocks[i].type,
            data: {
              text: entry,
            },
          });
        });
        // const text = paragraph.text.replaceAll("&nbsp;", "");
        // convertArray.push({
        //   id: blocks[i].id,
        //   type: blocks[i].type,
        //   data: {
        //     text: text,
        //   },
        // });
      } else if (blocks[i].type === "linkTool") {
        const paragraph = blocks[i].data as LINKTOOL;

        const link = paragraph.link;
        convertArray.push({
          id: blocks[i].id,
          type: blocks[i].type,
          data: {
            link: link,
          },
        });
      }
    }

    return convertArray;
  };
  const handlePostProduct = async (value: any) => {
    if (!data) {
      api.error({
        message: "공지 상세내용 등록은 필수입니다.",
        description: "상세내용을 작성하시기 바랍니다.",
      });
      return;
    }

    if (uploadFileList.length === 0) {
      api.error({
        message: "공지사항 썸네일 등록은 필수입니다.",
        description: "이미지를 등록해주세요",
      });
      return;
    }
    try {
      setLoading(true);

      const images = (await handleUploadCompMultiFiles()) ?? [];

      const descriptionEditorData = (await handleConvertEditorBlocks()) ?? [];

      const formData = {
        title: value.title.trim(),
        content: descriptionEditorData,
        thumbnail: images[0],
      };

      const res = await LPPAXIOS.put(`/admin/notice/${id}`, formData);
      if (res.status === 200) {
        api.success({
          message: `공지사항 수정을 성공하였습니다.`,
          description: `product load success`,
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Notation.Main.url());
        }, 500);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: `공지사항 수정을 실패하였습니다.`,
          description: `${error.response?.data.message}`,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const startEdit = () => {
      const imageFileList = location.state.thumbnail;
      for (let i = 0; i < imageFileList.length; i++) {
        const previewFile: UploadFile = {
          uid: `done file ${i}`,
          name: imageFileList[i],
          status: "done",
          url: `${process.env.REACT_APP_IMG_ENDPOINT}/${imageFileList[i]}`,
        };
        setUploadFileList((prev) => [...prev, previewFile]);
      }
      let findEditorBlocks = location.state.content;
      for (let i = 0; i < findEditorBlocks.length; i++) {
        findEditorBlocks[i].id = `done file ${i}`;
        if (findEditorBlocks[i].type === "linkTool") {
          findEditorBlocks[i] = {
            id: `done file ${i}`,
            type: "paragraph",
            data: {
              text: findEditorBlocks[i].data.link,
            },
          };
        }
      }
      setData({ blocks: findEditorBlocks });
    };
    startEdit();
  }, []);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="drop-shadow-sm"
      >
        <Title>공지사항 수정</Title>
      </Header>
      <Content className="m-6">
        <Form
          onFinish={handlePostProduct}
          style={{ width: 800 }}
          initialValues={{ ...location.state }}
        >
          <Form.Item label="공지 썸네일">
            <Upload
              fileList={uploadFileList}
              accept="image/*"
              listType="picture-card"
              showUploadList={{ showPreviewIcon: false }}
              customRequest={({ onSuccess }) => {
                const res = "Ok";
                setTimeout(() => {
                  onSuccess!(res);
                }, 600);
              }}
              onChange={({ fileList }) => {
                setUploadFileList(fileList);
              }}
            >
              {uploadFileList.length >= 1 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="공지제목"
            name="title"
            rules={[
              { required: true, message: "공지사항 제목 입력은 필수입니다." },
              {
                min: 8,
                message:
                  "공지사항 제목은 최소 8글자 이상 입력해주시기 바랍니다.",
              },
            ]}
          >
            <Input
              showCount
              maxLength={100}
              onChange={preventSpaceBar}
              onInput={preventSpaceBar}
            />
          </Form.Item>
          <Divider />
          <Typography.Title level={4}>공지사항 상세 설명</Typography.Title>
          <div className="m-4 w-[100%] p-[5%] bg-[#f4f5f9]">
            <Editor data={{ blocks: data }} setData={setData} />
          </div>
          <Form.Item>
            <div className="flex justify-center w-full">
              <Button type="primary" htmlType="submit">
                공지사항 수정
              </Button>
              {/* <Button onClick={() => console.log(data)}>
                공지사항 dfdfdfdfdfdf수정
              </Button> */}
            </div>
          </Form.Item>
        </Form>
        {/* <Button onClick={() => console.log(data.blocks)}>log</Button>
            <Button onClick={() => console.log(uploadFileList)}>UploadFile</Button> */}
      </Content>
    </>
  );
};
