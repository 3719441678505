import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Card,
  Divider,
  Image,
  Space,
  Result,
  Button,
  Carousel,
  Popconfirm,
} from "antd";

import { CarOutlined } from "@ant-design/icons";
import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios, { AxiosError } from "axios";

import { HContent } from "../../../components/common/HContent";
import useNotification from "antd/es/notification/useNotification";
import { urlRegex } from "../../../utils/converter";

const { Header } = Layout;
const { Title } = Typography;

interface File {
  url: string;
}

interface Data {
  file: File;
  text: string;
}

interface Description {
  id: string;
  data: Data;
  type: string;
  caption: string;
  stretched: boolean;
  withBorder: boolean;
  withBackground: boolean;
}

interface RESPONSETYPE {
  id: number;
  name: string;
  price: number;
  discounted_price: number;
  image: string[];
  delivery_fee: number;
  stock: number;
  contents_description: string;
  discount: number;
  description: Description[];
  origin: string;
  brand: string;
  isBanner: boolean;
  banner_image: string[];
  category: string;
  recommend: boolean;
}

export const DetailPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RESPONSETYPE>({
    id: 1,
    name: "string",
    price: 1,
    discounted_price: 1,
    image: [],
    delivery_fee: 1,
    stock: 1,
    contents_description: "string",
    discount: 1,
    description: [],
    origin: "string",
    brand: "string",
    isBanner: false,
    banner_image: [],
    category: "string",
    recommend: false,
  });
  const [error, setError] = useState<AxiosError | Error | null>(null);

  const [api, contextHolder] = useNotification();

  const { id } = useParams();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handlePostRecommendProduct = () => {
    //추천 상품 만들기
    LPPAXIOS.post(`/admin/product/recommend/${data!.id}`).then(() => {
      api.success({
        message: "추천 상픔으로 등록되었습니다.",
      });
      setData((prev) => {
        return { ...prev, recommend: true };
      });
    });
  };

  const handleDeleteRecommendProduct = () => {
    //추천 상품 만들기 취소
    LPPAXIOS.delete(`/admin/product/recommend/${data!.id}`).then(() => {
      api.success({
        message: "추천 상픔에서 일반상품으로 변경되었습니다.",
      });
      setData((prev) => {
        return { ...prev, recommend: false };
      });
    });
  };

  const handleUpdateProduct = () => {
    navigate(LPPUrls.Admin.Products.Update.url(data!.id), { state: data });
  };

  const handleDeleteProduct = (id: string | number) => {
    LPPAXIOS.delete(`/admin/product?id=${id}`)
      .then(() => {
        api.success({
          message: "상품을 삭제하였습니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Products.Main.url());
        }, 500);
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          api.error({
            message: "상품을 찾을 수 없습니다.",
          });
        } else {
          api.error({
            message: "상품을 찾을 수 없습니다.",
          });
        }
      });
  };

  const handleSoldOut = async (id: string | number) => {
    setLoading(false);
    LPPAXIOS.post(`/admin/product/sold/${id}`)
      .then(() => {
        api.success({
          message: "상품이 품절 상태로 변경되었습니다.",
        });
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          api.error({
            message: "해당 상품을 찾을 수 없습니다.",
          });
        } else {
          api.error({
            message: "해당 요청을 수행 할 수 없습니다. 관리자에게 요청하세요.",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(`/admin/product/${id}`)
      .then((res) => {
        const editBlocks = res.data.description;
        for (let i = 0; i < editBlocks.length; i++) {
          if (editBlocks[i].type === "image") {
            editBlocks[
              i
            ].data.file.url = `${process.env.REACT_APP_IMG_ENDPOINT}/${editBlocks[i].data.file.url}`;
          }
        }
        setData({ ...res.data, description: editBlocks });
      })
      .catch((error: Error | AxiosError) => {
        api.error({
          message: "상품 정보를 불러오지 못했습니다.",
        });
        if (axios.isAxiosError(error)) {
          return setError(error);
        }
        return setError(error);
      })
      .finally(() => setLoading(false));
  }, [id, api]);

  if (error !== null)
    return (
      <Result
        title={error?.message}
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary">Back Home</Button>}
      />
    );
  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>상품</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Products.Main.url()}>상품</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Space className="flex justify-end mb-2">
          {data?.recommend === false ? (
            <Button onClick={handlePostRecommendProduct}>추천상품 등록</Button>
          ) : (
            <Button onClick={handleDeleteRecommendProduct}>
              추천상품 취소
            </Button>
          )}{" "}
          <Space>
            <Button type="text" onClick={handleUpdateProduct}>
              상품 수정
            </Button>
            |
            <Popconfirm
              title="상품 삭제"
              description="해당 상품을 삭제하시겠습니까?"
              onConfirm={() => handleDeleteProduct(id!)}
              okText="예"
              cancelText="아니오"
            >
              <Button type="text">삭제</Button>
            </Popconfirm>
            |
            <Popconfirm
              title="상품 품절"
              description="해당 상품이 품절 상태가 됩니다."
              onConfirm={() => handleSoldOut(id!)}
              okText="예"
              cancelText="아니오"
            >
              <Button type="text">품절</Button>
            </Popconfirm>
          </Space>
        </Space>

        <Space className="w-full">
          <Carousel autoplay className="w-[450px]">
            {data?.image.map((item, idx) => (
              <div
                className="flex justify-center bg-black"
                key={`caroucel-${idx}`}
              >
                <Image
                  src={`${process.env.REACT_APP_IMG_ENDPOINT}/${item}`}
                  alt="product_thumbnail"
                  className="h-[300px] object-cover"
                />
              </div>
            ))}
          </Carousel>
          <Card>
            <Typography.Title level={3}>{data?.name}</Typography.Title>

            <Space direction="vertical">
              <Typography.Text>{data?.contents_description}</Typography.Text>
              <Typography.Text>ID:#{data?.id}</Typography.Text>
              <Typography.Text>
                재고:{" "}
                {data?.stock === 0 ? (
                  <Typography.Text strong type="danger">
                    품절
                  </Typography.Text>
                ) : (
                  data?.stock.toLocaleString("Kr")
                )}
              </Typography.Text>
              <Typography.Text type="secondary">
                배송기간: 2~3일 소요(영업일 기준)
              </Typography.Text>
            </Space>
            <Divider />
            <Space className="flex items-end">
              <Typography.Text strong className="text-lg text-red-600">
                &#8361;{data?.discounted_price.toLocaleString("Kr")}
              </Typography.Text>
              <Typography.Text delete disabled>
                &#8361;{data?.price.toLocaleString("Kr")}
              </Typography.Text>
            </Space>

            <Divider />
            <Space>
              <CarOutlined /> 배송비:
              <Typography.Text>
                &#8361;{data?.delivery_fee.toLocaleString("Kr")}
              </Typography.Text>
            </Space>
          </Card>
        </Space>
        {/* {data?.isBanner === true && (
          <Space className="flex flex-col items-center">
            <Typography.Text>상품 배너 이미지</Typography.Text>
            <Image
              width={360}
              height={160}
              src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.banner_image[0]}`}
            />
          </Space>
        )} */}
        <Divider />

        <Typography>
          <Typography.Title level={4}>상품 상세 정보</Typography.Title>
          {data?.description.map((item) => {
            if (item.type === "image") {
              return (
                <div key={item.id} className="max-w-md">
                  <Image src={`${item.data.file.url}`} />
                </div>
              );
            } else {
              if (urlRegex.test(item.data.text)) {
                return (
                  <p>
                    <a href={item.data.text} target="_blank">
                      {item.data.text}
                    </a>
                  </p>
                );
              } else {
                return (
                  <Typography.Text key={item.id}>
                    {item.data.text}
                  </Typography.Text>
                );
              }
            }
          })}
        </Typography>
        {/* <EditorTextParser
          data={{
            blocks: data?.description ?? [],
          }}
        /> */}
      </HContent>
      {contextHolder}
    </>
  );
};
