import { BrowserRouter } from "react-router-dom";
import { LPPRootRoutes } from "./components/routes/LPPRootRoutes";
import { ConfigProvider } from "antd";

import "antd/dist/reset.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import koKR from "antd/locale/ko_KR";
import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

const queryClient = new QueryClient();
const locale = koKR;

function App() {
  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorLink: "#4D9226",
          colorLinkHover: "#F9BB06",
          colorPrimary: "#4D9226",
          colorPrimaryHover: "#5FB030",
          colorSuccess: "#00c400",
          colorTextBase: "#1f1f1f",
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <LPPRootRoutes />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
