import {
  Card,
  Col,
  DatePicker,
  Row,
  Spin,
  Statistic,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { LoadingOutlined } from "@ant-design/icons";
import { LPPAXIOS } from "../../../../framework/api/core.api";
import { ColumnsType } from "antd/es/table";
import { timeConverter } from "../../../../utils/timeConverter";
import { RangePickerProps } from "antd/es/date-picker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
dayjs.extend(isBetween);

const rangePresets: {
  label: string;
  value: [Dayjs, Dayjs];
}[] = [
  { label: "지난 7일", value: [dayjs().add(-6, "d"), dayjs()] },
  { label: "지난 14일", value: [dayjs().add(-13, "d"), dayjs()] },
  { label: "지난 30일", value: [dayjs().add(-29, "d"), dayjs()] },
  { label: "지난 90일", value: [dayjs().add(-89, "d"), dayjs()] },
];
const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today and today
  return current && current > dayjs().endOf("day");
};

const recordColumn: ColumnsType<Record> = [
  {
    title: "이동수단",
    dataIndex: "moving_type",
    render: (_, record) => {
      if (record.moving_type === "walking") return "걷기";
      if (record.moving_type === "bus") return "버스";
      if (record.moving_type === "bicycle") return "자전거";
      return "걷기";
    },
  },
  {
    title: "이동거리",
    dataIndex: "moving_distance",
    render: (_, record) => `${record.moving_distance}km`,
  },
  {
    title: "탄소감축량",
    dataIndex: "co2_reduction",
    render: (_, record) => `${record.co2_reduction} kg/co2`,
  },
  {
    title: "심은 소나무",
    dataIndex: "planted_tree",
    render: (_, record) => `${record.planted_tree}그루`,
  },
  {
    title: "기록 시간",
    dataIndex: "createdAt",
    render: (_, record) => timeConverter(record.createdAt),
  },
];
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "포인트",
    },
  },
  scales: {
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
    },
    y1: {
      type: "linear" as const,
      display: true,
      position: "right" as const,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};
interface ENTITY {
  date: string;
  co2_reduction: number;
  moving_distance: number;
  amount: number;
  records: Record[];
}
interface Record {
  recordId: string;
  moving_distance: number;
  co2_reduction: number;
  planted_tree: number;
  point: number;
  moving_type: string;
  createdAt: string;
  date: string;
}
interface Total {
  total_moving_distance: number;
  total_co2_reduction: number;
  total_planted_tree: number;
}
interface RESPONSETYPE {
  data: ENTITY[];

  total_point: number;
  total: Total;
}
interface IPointChartProps {
  userId: string | number;
}
export const PointChart = (props: IPointChartProps) => {
  const { userId } = props;

  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  >([dayjs().subtract(14, "d"), dayjs()]);
  const [data, setData] = useState<ENTITY[]>([]);
  const [record, setRecord] = useState<Record[]>([]);
  const [total, setTotal] = useState<Total>({
    total_moving_distance: 0,
    total_co2_reduction: 0,
    total_planted_tree: 0,
  });

  const graphData = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: "포인트",
        data: data.map((item) => item.amount),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y",
      },
      {
        label: "탄소 감축량",
        data: data.map((item) => item.co2_reduction),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        yAxisID: "y1",
      },
    ],
  };

  const handleOnChangeDaysRange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  ) => {
    if (dates === null) return;
    if (dates[0] !== null && dates[1] !== null) {
      setDays(dates);
      setLoading(true);
      LPPAXIOS.get<RESPONSETYPE>(
        `/admin/users/${userId}/record?start_date=${dates[0].format(
          "YYYY-MM-DD"
        )}&end_date=${dates[1].format("YYYY-MM-DD")}`
      )
        .then((res) => {
          setData(res.data.data.reverse());
          setTotal(res.data.total);
          const resolve: Record[] = [];

          res.data.data.forEach((item, index) => {
            if (item.records.length !== 0) {
              resolve.push(...item.records.reverse());
            }
          });

          setRecord(resolve);
        })
        .catch((error) => {
          setData([]);

          setTotal({
            total_moving_distance: 0,
            total_co2_reduction: 0,
            total_planted_tree: 0,
          });
        })
        .finally(() => setLoading(false));
    }
    return;
  };

  useEffect(() => {
    if (days !== null && days[0] !== null && days[1] !== null) {
      setLoading(true);
      LPPAXIOS.get<RESPONSETYPE>(
        `/admin/users/${userId}/record?start_date=${days[0].format(
          "YYYY-MM-DD"
        )}&end_date=${days[1].format("YYYY-MM-DD")}`
      )
        .then((res) => {
          setData(res.data.data.reverse());
          setTotal(res.data.total);
          const resolve: Record[] = [];

          res.data.data.reverse().forEach((item, index) => {
            if (item.records.length !== 0) {
              resolve.push(...item.records.reverse());
            }
          });

          setRecord(resolve.reverse());
        })
        .catch((error) => {
          setData([]);

          setTotal({
            total_moving_distance: 0,
            total_co2_reduction: 0,
            total_planted_tree: 0,
          });
        })
        .finally(() => setLoading(false));
    }
  }, [days, userId]);

  return (
    <>
      <Card
        className="relative w-full h-full"
        title={
          <div className="flex items-start justify-between p-2">
            <Typography.Title level={4}>상세 기록</Typography.Title>

            <DatePicker.RangePicker
              defaultValue={days}
              disabledDate={disabledDate}
              presets={rangePresets}
              onChange={handleOnChangeDaysRange}
              onOpenChange={() => setDays(null)}
            />
          </div>
        }
      >
        <Row gutter={24}>
          <Col span={8}>
            <Statistic
              title="이동거리 (km)"
              value={total.total_moving_distance}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="탄소 감축량 (kg/co2)"
              value={total.total_co2_reduction}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="심은 소나무 (그루)"
              value={total.total_planted_tree}
            />
          </Col>
        </Row>

        <Table
          dataSource={record}
          columns={recordColumn}
          rowKey={(render) => render.recordId}
        />
        {loading ? (
          <div className="flex justify-center items-center w-full min-h-[245px]">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : (
          <>
            <Line height="80%" data={graphData} options={options} />
          </>
        )}
      </Card>
    </>
  );
};
