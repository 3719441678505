import {
  Layout,
  theme,
  Typography,
  Form,
  Input,
  Button,
  notification,
  Space,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";

export interface Daum {
  id: number;
  url: string;
  description: string;
}
interface RESPONSETYPE {
  data: Daum[];
  total: number;
}

const { Header, Content } = Layout;
const { Title } = Typography;

export const SettingRoot = () => {
  const [loading, setLoading] = useState(false);
  const [settingMap, setSettingMap] = useState<Daum[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onFinishDonation = async (value: any) => {
    try {
      const res = await LPPAXIOS.put(`/admin/url/2`, {
        url: value.donation_url,
        description: "후원 Url",
      });
      if (res.status === 200) {
        setSettingMap((prev) => [
          prev[0],
          {
            id: 2,
            description: "후원 url",
            url: value.donation_url,
          },
        ]);
        api.success({
          message: "후원하기 URL을 수정하였습니다.",
          description: "",
          duration: 2,
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: "설정 변경을 실패하였습니다.",
          description: error.message,
          duration: 2,
        });
      }
    }
  };
  const onFinishCampagin = async (value: any) => {
    try {
      const res = await LPPAXIOS.put(`/admin/url/1`, {
        url: value.campagin_url,
        description: "캠페인 Url",
      });
      if (res.status === 200) {
        setSettingMap((prev) => [
          {
            id: 1,
            description: "캠페인 url",
            url: value.campagin_url,
          },
          prev[1],
        ]);

        api.success({
          message: "캠페인 URL을 수정하였습니다.",
          description: "",
          duration: 2,
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: "설정 변경을 실패하였습니다.",
          description: error.message,
          duration: 2,
        });
      }
    }
  };

  const onFinishExChange = async (value: any) => {
    try {
      const res = await LPPAXIOS.put(`/admin/url/3`, {
        url: "취소/환불정책",
        description: value.description,
      });
      if (res.status === 200) {
        setSettingMap((prev) => [
          prev[0],
          prev[1],
          {
            id: 3,
            description: value.description,
            url: "취소/환불정책",
          },
        ]);

        api.success({
          message: "취소/환불정책을 수정하였습니다.",
          description: "",
          duration: 2,
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: "설정 변경을 실패하였습니다.",
          description: error.message,
          duration: 2,
        });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(`/admin/url?start=1&perPage=5`)
      .then((res) => {
        setSettingMap(res.data.data);
      })
      .catch((error) => {
        api.error({
          message: "기본 설정 값을 가져오지 못했습니다.",
          description: error.message,
          duration: 2,
        });
      })
      .finally(() => setLoading(false));
  }, [api]);

  const onClickFCM = () => {
    LPPAXIOS.get(`/admin/fcm/update`)
      .then((resolve) => {
        api.success({ message: "알림 발송 완료" });
      })
      .catch(function (error) {
        if (error.response) {
          api.error({
            message: "알림 발송할 수 없습니다.",
            description: error.response.data.message ?? "",
          });
        }
      });
  };
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>설정</Title>
      </Header>
      <Content className="p-2 m-6 bg-white">
        {!loading && (
          <>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              style={{ maxWidth: 900 }}
              onFinish={onFinishCampagin}
              initialValues={{
                campagin_url: settingMap[0]?.url,
                // donation_url: settingMap[1].url,
              }}
            >
              <Form.Item label={"캠페인 소개 URL"}>
                <Space>
                  <Form.Item
                    name={"campagin_url"}
                    rules={[
                      {
                        required: true,

                        message: "캠페인 소개 링크를 작성해주식 바랍니다.",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: 500 }}
                      maxLength={1000}
                      onInput={(e) => {
                        e.currentTarget.value = e.currentTarget.value.trim();
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 4,
                    }}
                  >
                    <Button type="primary" htmlType="submit">
                      {"저장하기"}
                    </Button>
                  </Form.Item>
                </Space>
              </Form.Item>
            </Form>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              style={{ maxWidth: 900 }}
              onFinish={onFinishDonation}
              initialValues={{
                donation_url: settingMap[1]?.url,
              }}
            >
              <Form.Item label={"후원하기 URL"}>
                <Space>
                  <Form.Item
                    name={"donation_url"}
                    rules={[
                      {
                        required: true,
                        message: "후원 링크를 작성해주식 바랍니다.",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: 500 }}
                      maxLength={1000}
                      onInput={(e) => {
                        e.currentTarget.value = e.currentTarget.value.trim();
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      offset: 4,
                    }}
                  >
                    <Button type="primary" htmlType="submit">
                      {"저장하기"}
                    </Button>
                  </Form.Item>
                </Space>
              </Form.Item>
            </Form>

            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              style={{ maxWidth: 900 }}
              onFinish={onFinishExChange}
              initialValues={{
                description: settingMap[2]?.description,
              }}
            >
              <Form.Item label={"취소 및 환불 안내"}>
                <Space className="flex items-start">
                  <Form.Item
                    name={"description"}
                    rules={[
                      {
                        required: true,
                        message: "내용을 입력하세요",
                      },
                    ]}
                  >
                    <Input.TextArea
                      style={{ width: 500 }}
                      showCount
                      maxLength={5000}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.trim();
                      // }}
                      rows={10}
                    />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      offset: 4,
                    }}
                  >
                    <Button type="primary" htmlType="submit">
                      {"저장하기"}
                    </Button>
                  </Form.Item>
                </Space>
              </Form.Item>
            </Form>
            <Form style={{ maxWidth: 500 }} onFinish={onClickFCM}>
              <Button type="primary" htmlType="submit" block>
                앱 업데이트 알림발송
              </Button>
            </Form>
          </>
        )}
      </Content>
    </>
  );
};
