import { Routes, Route, Navigate } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import { CreatePage } from "./Create";
import { DetailPage } from "./Detail";
// import { RecordPage } from "./Record";
import { TablePage } from "./Table";
import { UpdatePage } from "./Update";
import { UserPage } from "./User";

export const ChallengeRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />

      <Route
        path={LPPUrls.Admin.Challenge.Detail.pathName}
        element={<DetailPage />}
      />
      <Route
        path={LPPUrls.Admin.Challenge.Create.pathName}
        element={<CreatePage />}
      />
      <Route
        path={LPPUrls.Admin.Challenge.Update.pathName}
        element={<UpdatePage />}
      />
      {/* <Route
        path={LPPUrls.Admin.Challenge.Record.pathName}
        element={<RecordPage />}
      /> */}

      <Route
        path={LPPUrls.Admin.Challenge.User.pathName}
        element={<UserPage />}
      />

      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};
