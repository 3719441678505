import { Routes, Route, Navigate } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import { CreatePage } from "./Create";
import { DetailPage } from "./Detail";
import { TablePage } from "./Table";
import { UpdatePage } from "./Update";

export const NotationRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />

      <Route
        path={LPPUrls.Admin.Notation.Detail.pathName}
        element={<DetailPage />}
      />
      <Route
        path={LPPUrls.Admin.Notation.Create.pathName}
        element={<CreatePage />}
      />

      <Route
        path={LPPUrls.Admin.Notation.Update.pathName}
        element={<UpdatePage />}
      />

      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};
