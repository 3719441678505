import {
  Layout,
  Typography,
  theme,
  Table,
  Pagination,
  PaginationProps,
  Image,
  Button,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { DownloadOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { GoogleMap, InfoWindow, useJsApiLoader } from "@react-google-maps/api";

import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import useNotification from "antd/es/notification/useNotification";
import { HContent } from "../../../components/common/HContent";
import { timeConverter } from "../../../utils/timeConverter";

const { Header } = Layout;
const { Title } = Typography;

interface RESPONSETYPE {
  data: Report[];
  total: number;
}

interface Report {
  id: number;
  userId: number;
  nickName: string;
  picture: string;
  content: string;
  longitude: string;
  latitude: string;
  createdAt: string;
}

const defaultCenter = { lat: 37.5429, lng: 126.9504 };
const defaultZoom = 12;
const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  cursor: "pointer",
};

export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP}`,
  });
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = useNotification();
  const [data, setData] = useState<Report[]>([]);

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    map.setZoom(defaultZoom);
    map.setCenter(defaultCenter);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const clubListColumn: ColumnsType<Report> = [
    { title: "신고자", dataIndex: "nickName" },
    {
      title: "picture",
      dataIndex: "picture",
      render: (_, record) => (
        <Image
          height={80}
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${record.picture}`}
        />
      ),
    },
    { title: "신고 내용", dataIndex: "content", ellipsis: true },
    {
      title: "신고 시각",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt).slice(0, 11),
    },
    {
      title: (
        <a
          href={`${process.env.REACT_APP_ENDPOINT}${"/admin/report/download"}`}
          target="_blank"
        >
          <Button icon={<DownloadOutlined />} size="small">
            신고 리스트 다운로드
          </Button>
        </a>
      ),
      dataIndex: "operation",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() =>
            navigate(LPPUrls.Admin.Report.Detail.url(record.id), {
              state: record,
            })
          }
        >
          상세보기
        </Button>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(`/admin/report?page=${currentPage}&perPage=25`)
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) =>
        api.error({
          message: "신고 목록을 불러오지 못했습니다.",
        })
      )
      .finally(() => setLoading(false));
  }, [currentPage, api]);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(LPPUrls.Admin.Report.Main.url(page));
    setCurrentPage(page);
  };

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>신고</Title>
      </Header>
      <HContent>
        {isLoaded ? (
          <GoogleMap
            options={{ mapTypeControl: false }}
            mapContainerStyle={{ width: "100%", height: "500px" }}
            center={defaultCenter}
            zoom={defaultZoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {data.map((marker, index) => (
              <InfoWindow
                key={index}
                position={{
                  lat: Number(marker.latitude),
                  lng: Number(marker.longitude),
                }}
              >
                <div
                  style={divStyle}
                  onClick={() =>
                    navigate(LPPUrls.Admin.Report.Detail.url(marker.userId), {
                      state: marker,
                    })
                  }
                >
                  <img
                    width={50}
                    height={50}
                    src={`${process.env.REACT_APP_IMG_ENDPOINT}/${marker.picture}`}
                    alt="user_report_img_url"
                  />
                </div>
              </InfoWindow>
            ))}
          </GoogleMap>
        ) : (
          <div style={{ width: "100%", height: "500px" }}>
            GoogleMap load...
          </div>
        )}

        <Table
          pagination={false}
          rowKey={(render) => render.picture}
          dataSource={data}
          columns={clubListColumn}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </HContent>
      {contextHolder}
    </>
  );
};
