const topLevelAdmin = (name: string) => {
  return {
    Main: {
      url: (page: number = 1, keyword: string = "") =>
        `/admin/${name}?page=${page}&keyword=${keyword}`,
      pathName: `/${name}/*`,
      url2: (params?: string) => `/admin/${name}${params ? params : ""}`,
    },
    key: name,

    ReportList: {
      url: `/admin/${name}/ReportList/`,
      pathName: `ReportList/*`,
      key: `${name}ReportList`,
    },

    Detail: {
      url: (id: string | number) => `/admin/${name}/detail/${id}`,
      pathName: "detail/:id",
      key: `${name}detail`,
    },
    Update: {
      url: (id: string | number) => `/admin/${name}/update/${id}`,
      pathName: "update/:id",
      key: `${name}update`,
    },
    Create: {
      url: `/admin/${name}/create/`,
      pathName: `create/`,
      key: `${name}create`,
    },
    BoardInfo: {
      url: (id: string | number) => `/admin/${name}/boardInfo/${id}`,
      pathName: "boardInfo/:id",
      key: `${name}boardInfo`,
    },
    UploadBanner: {
      url: (id: string | number) => `/admin/${name}/uploadBanner/${id}`,
      pathName: "uploadBanner/:id",
      key: `${name}uploadBanner`,
    },
  };
};

export const LPPUrls = {
  Auth: {
    Root: {
      url: "/auth",
      pathName: "/auth/*",
    },
    Login: {
      Main: {
        url: "/auth/adminLogin",
        pathName: "adminLogin",
      },
    },
    FindPassword: {
      url: "/auth/findPassword",
      pathName: "findPassword",
    },
  },
  Admin: {
    // url:'/admin',
    // Landing: '/admin/adminAccount/',
    Root: {
      url: "/admin",
      pathName: "/admin/*",
    },
    MyPage: topLevelAdmin("MyPage"),
    adminAccount: topLevelAdmin("adminAccount"),
    Notation: topLevelAdmin("Notation"),
    Clubs: topLevelAdmin("Clubs"),
    Users: topLevelAdmin("User"),
    Writes: topLevelAdmin("Writes"),
    Search: topLevelAdmin("Search"),
    Statistic: topLevelAdmin("Statistic"),
    Setting: topLevelAdmin("Setting"),
    QnA: topLevelAdmin("QnA"),
    Term: topLevelAdmin("Term"),
    Products: {
      ...topLevelAdmin("Products"),
      Category: {
        url: `/admin/Products/category/`,
        pathName: "category/",
        key: `Productscategory`,
      },
    },
    Orders: topLevelAdmin("Orders"),
    Report: topLevelAdmin("Report"),
    Challenge: {
      ...topLevelAdmin("Challenge"),
      Record: {
        url: (page: number = 1, keyword: string = "") =>
          `/admin/${"Challenge"}/record?page=${page}&keyword=${keyword}`,
        pathName: `record/*`,
        key: `Challengerecord`,
      },
      User: {
        url: (id: string | number) => `/admin/Challenge/user/${id}`,
        pathName: "user/:id",
        key: `$Challengeuser`,
      },
    },
    Record: topLevelAdmin("Record"),
    Donation: topLevelAdmin("Donation"),
    Campagin: topLevelAdmin("Campagin"),
    Banner: topLevelAdmin("Banner"),
    Rank: topLevelAdmin("Rank"),
    ExpiredPoint: topLevelAdmin("ExpiredPoint"),
  },
  App: {
    Root: {
      url: "/app",
      pathName: "/app/*",
    },
    Login: {
      Naver: {
        url: "/app/naverLogin",
        pathName: "naverLogin",
      },
    },
    Redirect: {
      url: "/app/redirect",
      pathName: "redirect",
    },
  },
};
