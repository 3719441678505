import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import { CategoryPage } from "./Category";
import { CreatePage } from "./Create";
import { DetailPage } from "./Detail";
import { TablePage } from "./Table";
import { UpdatePage } from "./Update";

export const ProductsRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />
      <Route
        path={LPPUrls.Admin.Products.Detail.pathName}
        element={<DetailPage />}
      />
      <Route
        path={LPPUrls.Admin.Products.Create.pathName}
        element={<CreatePage />}
      />
      <Route
        path={LPPUrls.Admin.Products.Update.pathName}
        element={<UpdatePage />}
      />
      {/* <Route
        path={LPPUrls.Admin.Products.UploadBanner.pathName}
        element={<UPloadBannerPage />}
      /> */}
      <Route
        path={LPPUrls.Admin.Products.Category.pathName}
        element={<CategoryPage />}
      />
      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};
