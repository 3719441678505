import {
  Layout,
  Typography,
  theme,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Divider,
  Pagination,
  PaginationProps,
  Switch,
  notification,
  Space,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";

import { LPPUrls } from "../../../LPPUrls";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { AdminTag } from "../../../components/common/AdminTag";
import { StaffTag } from "../../../components/common/StaffTag";
import { LoadingSpin } from "../../../components/common/Loading";
import { timeConverter } from "../../../utils/timeConverter";
import { WithTooltipInfo } from "../../../components/common/WithTooltip";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import axios from "axios";

enum ACTIVETYPE {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
enum LevelType {
  ADMIN = "admin",
  STAFF = "staff",
}

interface AdminDataType {
  id: number;
  name: string;
  role: LevelType;
  createdAt: string;
  status: ACTIVETYPE;
}

interface GETDATATYPE {
  data: AdminDataType[];
  total: number;
}

interface PostAmdinistrators {
  name: string;
  role: LevelType;
  password: string;
  status: ACTIVETYPE;
}

interface PutAdministrators {
  id: number;
  role: LevelType;
  status: ACTIVETYPE;
}

const { Title } = Typography;
const { Header, Content } = Layout;

const LvOption = [
  { label: "관리자", value: LevelType.ADMIN },
  { label: "스태프", value: LevelType.STAFF },
];

const StatusOption = [
  { label: "활성", value: ACTIVETYPE.ACTIVE },
  { label: "비활성", value: ACTIVETYPE.INACTIVE },
];

export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AdminDataType[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const AdminColumn: ColumnsType<AdminDataType> = [
    {
      title: "이름",
      dataIndex: "name",
    },
    {
      title: "생성일",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt),
    },
    {
      title: "권한",
      dataIndex: "LevelType",
      render: (_, record) => {
        if (record.role === LevelType.ADMIN) return <AdminTag />;
        if (record.role === LevelType.STAFF) return <StaffTag />;
        return "-";
      },
    },

    {
      title: (
        <WithTooltipInfo
          title={
            <div>
              <p>ON: 관리자 활동이 가능하다.</p>
              <p>OFF: 관리자 활동이 불가능하다.</p>
            </div>
          }
          text="활성화 상태"
        />
      ),
      dataIndex: "status",
      render: (_, record) => (
        <Switch
          checkedChildren="ON"
          unCheckedChildren="OFF"
          loading={loading}
          checked={record.status === ACTIVETYPE.ACTIVE ? true : false}
          onClick={() => handleChangeClubActiveState(record)}
        />
      ),
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => (
        <Link
          className="font-medium text-blue-500 hover:text-blue-300"
          to={LPPUrls.Admin.adminAccount.Detail.url(record.id)}
        >
          상세 페이지
        </Link>
      ),
    },
  ];

  const handleChangeClubActiveState = async (record: AdminDataType) => {
    try {
      setLoading(true);
      let newState;
      if (record.status === ACTIVETYPE.ACTIVE) {
        newState = ACTIVETYPE.INACTIVE;
      }
      if (record.status === ACTIVETYPE.INACTIVE) {
        newState = ACTIVETYPE.ACTIVE;
      }
      await LPPAXIOS.put<PutAdministrators>(
        `/admin/administrators/${record.id}`,
        {
          status: newState,
          role: record.role,
        }
      ).then((res) =>
        setData((prev) =>
          prev.map((item) => {
            if (item.id === res.data.id) {
              return {
                name: item.name,
                createdAt: item.createdAt,
                ...res.data,
              };
            }
            return item;
          })
        )
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: "관리자 활성 상태 변경을 실패하였습니다.",
        });
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  const onFinish = async (value: PostAmdinistrators) => {
    try {
      setLoading(true);
      value = { ...value };

      const res = await LPPAXIOS.post<PostAmdinistrators>(
        "/admin/administrators",
        value
      );

      if (res.status === 201) {
        api.success({
          message: "",
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: "",
        });
      }
    } finally {
      setLoading(false);
      setOpen(false);
      window.location.reload();
    }
  };

  const showComfirm = () => {
    setOpen(true);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(LPPUrls.Admin.adminAccount.Main.url(page, ""));
    setCurrentPage(page);
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<GETDATATYPE>(
      `/admin/administrators?start=${currentPage}&perPage=25`
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: "관리자 목록을 불러오지 못했습니다.",
            description: error.response?.data.message,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [currentPage, api]);

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>Admin Account</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={AdminColumn}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Title level={5}>관리자 리스트</Title>
              <Button type="primary" onClick={showComfirm}>
                관리자 추가
              </Button>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />

        <Modal
          centered
          forceRender
          open={open}
          title={"관리자 추가"}
          footer={null}
          onCancel={() => {
            setOpen(false);
            form.resetFields();
          }}
          width={"50%"}
        >
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              role: LevelType.STAFF,
              status: ACTIVETYPE.INACTIVE,
            }}
          >
            <Form.Item
              label="이름"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input name!",
                  pattern: RegExp("^[0-9a-zA-Z!_]{4,20}$"),
                },
                { min: 5 },
              ]}
            >
              <Input maxLength={20} showCount onInput={preventSpaceBar} />
            </Form.Item>
            <Form.Item
              label="권한"
              name="role"
              tooltip={
                <Space direction="vertical">
                  <Typography.Text className="text-white">
                    관리자: 관리자 페이지 접속, 관리자 수정 및 추가 권한을
                    가집니다.
                  </Typography.Text>
                  <Typography.Text className="text-white">
                    스태프: 관리자 페이지 접속, 관리자 수정 및 추가 권한이
                    없습니다.
                  </Typography.Text>
                </Space>
              }
            >
              <Radio.Group options={LvOption} />
            </Form.Item>

            <Form.Item label="상태" name="status">
              <Radio.Group options={StatusOption} />
            </Form.Item>
            <Form.Item
              label="비밀번호"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input password!",
                  pattern: RegExp("^[0-9a-zA-Z!@#$%^&*()?+-_~=]{8,30}$"),
                },
                { min: 8 },
              ]}
            >
              <Input.Password
                maxLength={20}
                showCount
                onInput={preventSpaceBar}
              />
            </Form.Item>
            <Divider />
            <Form.Item>
              <div className="flex justify-end gap-2">
                <Button
                  onClick={() => {
                    setOpen(false);
                    form.resetFields();
                  }}
                >
                  cancel
                </Button>
                <Button htmlType="submit" type="primary" loading={loading}>
                  ok
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </Content>
      {contextHolder}
    </>
  );
};
