interface paramsFilterOptions {
  [key: string]: number | string | null;
}

export const paramsFilter = (options: paramsFilterOptions) => {
  let _url = "";

  Object.entries(options).forEach(([key, value]) => {
    if (value !== null) {
      _url += `&${key}=${value}`;
    }
  });
  _url = _url.replace("&", "?");
  console.log(_url);

  return _url;
};
interface Filter {
  [key: string]: string[] | null;
}

export function filterNonEmptyProperties(filter: Filter): {
  [key: string]: string | null;
} {
  const result: { [key: string]: string | null } = {};

  Object.entries(filter).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      result[key] = value[0];
    }
  });

  return result;
}
