import {
  Layout,
  Typography,
  theme,
  Table,
  Space,
  Button,
  Popconfirm,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { MenuOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
//import dayjs from "dayjs";

import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import useNotification from "antd/es/notification/useNotification";
import { HContent } from "../../../components/common/HContent";
import React from "react";

const { Header } = Layout;
const { Title } = Typography;

//상품명, 판매가격, 할인가격, 대표이미지, 등록일, 재고, 총판매량, 상태
//상태는 무엇을 의미하나??
type SORTDIC = {
  [key: string]: number;
};
interface RESPONSETYPE {
  data: QNA[];
  total: number;
}

interface QNA {
  id: number;
  question: string;
  answer: string;
}
interface DQNA {
  key: string;
  id: number;
  question: string;
  answer: string;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && { ...transform, scaleY: 1 }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};
const urlRegex = /(https?:\/\/[^\s]+)/;
export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [currentPage] = useState(Number(params.get("page")));
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = useNotification();
  const [data, setData] = useState<DQNA[]>([]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const columns: ColumnsType<DQNA> = [
    { key: "sort" },
    { title: "Qeustion", dataIndex: "question", width: "70%" },
    Table.EXPAND_COLUMN,
    {
      title: "",
      dataIndex: "action",
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="Q&A 삭제"
            description="Q&A를 삭제하시겠습니까?"
            onConfirm={() => handleDeleteQnA(record.id)}
            okText="예"
            cancelText="아니오"
          >
            <Button type="link">
              <Typography.Text type="danger">삭제</Typography.Text>
            </Button>
          </Popconfirm>

          <Button
            type="link"
            onClick={() =>
              navigate(LPPUrls.Admin.QnA.Update.url(record.id), {
                state: record,
              })
            }
          >
            수정
          </Button>
        </Space>
      ),
    },
  ];
  const handleDeleteQnA = async (id: string | number) => {
    setLoading(true);
    LPPAXIOS.delete(`/admin/question/${id}`)
      .then((resolve) => {
        setData(data.filter((item) => item.id !== id));
      })
      .catch(function (error) {
        api.error({
          message: "Q&A를 삭제할 수 없습니다.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      let previous = data;
      const activeIndex = previous.findIndex((i) => i.key === active.id);
      const overIndex = previous.findIndex((i) => i.key === over?.id);
      const result = arrayMove(previous, activeIndex, overIndex);

      setData(result);
      let sequance: SORTDIC = {};
      result.forEach((item, index) => (sequance[item.id] = index + 1));
      LPPAXIOS.post(`/admin/question/sorting`, {
        sequence: sequance,
      }).catch(function (error) {
        if (error.response.status === 400) {
          api.error({
            message: "잘못된 요청입니다.",
          });
        }
      });

      // Q&A 순서 수정 API 호출
    }
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(
      `/admin/question?start=${currentPage}&perPage=25`
    )
      .then((res) => {
        const reset: DQNA[] = [];
        res.data.data.forEach((item, index) => {
          reset.push({
            key: `${item.id}`,
            ...item,
          });
        });
        setData(reset);
      })
      .catch((error) =>
        api.error({
          message: "QNA 목록을 불러오지 못했습니다.",
        })
      )
      .finally(() => setLoading(false));
  }, [currentPage, api]);

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>Q&A</Title>
      </Header>
      <HContent>
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={data.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              title={() => (
                <Space>
                  <Button
                    type="primary"
                    onClick={() => navigate(LPPUrls.Admin.QnA.Create.url)}
                  >
                    Q&A 추가
                  </Button>
                </Space>
              )}
              expandable={{
                expandRowByClick: true,
                expandedRowRender: (record) => (
                  <div style={{ margin: 0 }}>
                    {record.answer.split("\n").map((item, index) => {
                      if (urlRegex.test(item)) {
                        return (
                          <p>
                            <a href={item} target="_blank">
                              {item}
                            </a>
                          </p>
                        );
                      } else {
                        return <p>{item}</p>;
                      }
                    })}
                  </div>
                ),
              }}
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey="key"
              columns={columns}
              dataSource={data}
            />
          </SortableContext>
        </DndContext>
      </HContent>
      {contextHolder}
    </>
  );
};
