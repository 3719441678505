import Image from "@editorjs/image";
import LinkTool from "@editorjs/link";

export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,

  image: {
    class: Image,
    config: {
      uploader: {
        uploadByFile(file) {
          return _getBase64(file, function (e) {}).then((data) => {
            return {
              success: 1,
              file: {
                mimetype: file.type,
                url: data,
                origin: file,
              },
            };
          });
        },
      },
    },
  },
};

export const EDITOR_JS_TOOLS_TEXT = {
  // linkTool: {
  //   class: LinkTool,
  //   inlineToolbar: true,
  //   config: {
  //     endpoint: "http://localhost:8008/fetchUrl", // Your backend endpoint for url data fetching,
  //     // LinkRenderer: handleLinkRenderer,
  //   },
  // },
};

function _getBase64(file, onLoadCallback) {
  return new Promise(function (resolve, reject) {
    var reader = new FileReader();
    reader.onload = function () {
      return resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
