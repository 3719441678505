import { Navigate, Route, Routes } from "react-router-dom";
// import { LPPUrls } from "../../../LPPUrls";
// import { DetailPage } from "./Detail";
import { TablePage } from "./Table";

export const ExpiredPointRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />
      {/* <Route
        path={LPPUrls.Admin.ExpiredPoint.Detail.pathName}
        element={<DetailPage />}
      /> */}
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};
