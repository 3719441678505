import { Link, useLocation, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Descriptions,
  Avatar,
  Row,
  Col,
  Result,
  DatePicker,
  Card,
  Statistic,
  Spin,
  Table,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { LPPUrls } from "../../../LPPUrls";
import dayjs from "dayjs";

import isBetween from "dayjs/plugin/isBetween";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { HContent } from "../../../components/common/HContent";
import { timeConverter } from "../../../utils/timeConverter";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Title as chatTitle,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { LoadingOutlined } from "@ant-design/icons";

const { Header } = Layout;
const { Title } = Typography;

interface ENTITY {
  date: string;
  co2_reduction: number;
  moving_distance: number;
  amount: number;
  records: Record[];
}
interface Record {
  recordId: string;
  moving_distance: number;
  co2_reduction: number;
  planted_tree: number;
  point: number;
  moving_type: string;
  createdAt: string;
  date: string;
}
interface Total {
  total_moving_distance: number;
  total_co2_reduction: number;
  total_planted_tree: number;
}
interface RESPONSETYPE {
  data: ENTITY[];

  total_point: number;
  total: Total;
}

interface USERDETAILTYPE {
  id: number;
  nickname: string;
  email: string;
  phone: string;
  type: string;
  profile: string;
  createdAt: string;
  point: number;
  movingDistance: number;
  co2Reduction: number;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  chatTitle,
  Tooltip,
  Filler,
  Legend
);
dayjs.extend(isBetween);

const recordColumn: ColumnsType<Record> = [
  {
    title: "이동수단",
    dataIndex: "moving_type",
    render: (_, record) => {
      if (record.moving_type === "walking") return "걷기";
      if (record.moving_type === "bus") return "버스";
      if (record.moving_type === "bicycle") return "자전거";
      return "걷기";
    },
  },
  {
    title: "이동거리",
    dataIndex: "moving_distance",
    render: (_, record) => `${record.moving_distance}km`,
  },
  {
    title: "탄소감축량",
    dataIndex: "co2_reduction",
    render: (_, record) => `${record.co2_reduction} kg/co2`,
  },
  {
    title: "심은 소나무",
    dataIndex: "planted_tree",
    render: (_, record) => `${record.planted_tree}그루`,
  },
  {
    title: "기록 시간",
    dataIndex: "createdAt",
    render: (_, record) => timeConverter(record.createdAt),
  },
];
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "포인트",
    },
  },
  scales: {
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
    },
    y1: {
      type: "linear" as const,
      display: true,
      position: "right" as const,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

export const UserPage = () => {
  const { id } = useParams();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<USERDETAILTYPE>();
  const [error, setError] = useState<any | AxiosError | null>(null);

  const [data, setData] = useState<ENTITY[]>([]);
  const [record, setRecord] = useState<Record[]>([]);
  const [total, setTotal] = useState<Total>({
    total_moving_distance: 0,
    total_co2_reduction: 0,
    total_planted_tree: 0,
  });
  const graphData = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: "포인트",
        data: data.map((item) => item.amount),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y",
      },
      {
        label: "탄소 감축량",
        data: data.map((item) => item.co2_reduction),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        yAxisID: "y1",
      },
    ],
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<USERDETAILTYPE>(`/admin/users/${id}`)
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          return setError(error);
        }
        return setError(error);
      })
      .finally(() => setLoading(false));
    LPPAXIOS.get<RESPONSETYPE>(
      `/admin/users/${id}/record?start_date=${dayjs(
        location.state.startDay
      ).format("YYYY-MM-DD")}&end_date=${dayjs(location.state.endDay).format(
        "YYYY-MM-DD"
      )}`
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
        const resolve: Record[] = [];

        res.data.data.forEach((item, index) => {
          if (item.records.length !== 0) {
            resolve.push(...item.records.reverse());
          }
        });

        setRecord(resolve);
      })
      .catch((error) => {
        setData([]);

        setTotal({
          total_moving_distance: 0,
          total_co2_reduction: 0,
          total_planted_tree: 0,
        });
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (error !== null) return <Result status={404} title={"404"} />;
  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>챌린지 참가 회원 상세 페이지</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Challenge.Main.url()}>챌린지</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>챌린지 참가 회원 상세페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <div className="flex items-center gap-3 p-2 bg-white border-2 rounded-md border-slate-600">
          <Avatar
            size={150}
            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${user?.profile}`}
          />

          <Descriptions bordered>
            <Descriptions.Item label="이름" span={3}>
              {user?.nickname}
            </Descriptions.Item>
            <Descriptions.Item label="이메일" span={3}>
              {user?.email}
            </Descriptions.Item>

            <Descriptions.Item label="가입 경로">
              {user?.type}
            </Descriptions.Item>
            <Descriptions.Item label="가입일">
              {timeConverter(user?.createdAt ?? "")}
            </Descriptions.Item>
            <Descriptions.Item label="포인트">
              {user?.point.toLocaleString("Kr")}
            </Descriptions.Item>
            <Descriptions.Item label="이동 거리">
              {user?.movingDistance.toLocaleString("Kr")}km
            </Descriptions.Item>
            <Descriptions.Item label="탄소 감축량">
              {user?.co2Reduction.toLocaleString("Kr")} kg/co2
            </Descriptions.Item>
          </Descriptions>
        </div>

        <Row gutter={[12, 12]}>
          <Col lg={16} md={16}></Col>
          <Card
            className="relative w-full h-full"
            title={
              <div className="flex items-start justify-between p-2">
                <Typography.Title level={4}>상세 기록</Typography.Title>
                <DatePicker.RangePicker
                  defaultValue={[
                    dayjs(location.state.startDay),
                    dayjs(location.state.endDay),
                  ]}
                  disabled
                />
              </div>
            }
          >
            <Row gutter={24}>
              <Col span={8}>
                <Statistic
                  title="이동거리 (km)"
                  value={total.total_moving_distance}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title="탄소 감축량 (kg/co2)"
                  value={total.total_co2_reduction}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title="심은 소나무 (그루)"
                  value={total.total_planted_tree}
                />
              </Col>
            </Row>

            <Table
              dataSource={record.reverse()}
              columns={recordColumn}
              rowKey={(render, index) =>
                `${render.recordId}${index}${render.createdAt}`
              }
            />
            {loading ? (
              <div className="flex justify-center items-center w-full min-h-[245px]">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              </div>
            ) : (
              <>
                <Line height="80%" data={graphData} options={options} />
              </>
            )}
          </Card>
        </Row>
      </HContent>
    </>
  );
};
