import {
  Layout,
  Typography,
  theme,
  Table,
  Input,
  notification,
  Pagination,
  PaginationProps,
  Space,
  Checkbox,
  Button,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaceBookTag } from "../../../components/common/FaceBookTag";
import { GoogleTag } from "../../../components/common/GoogleTag";
import { AppleTag } from "../../../components/common/AppleTag";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { timeConverter } from "../../../utils/timeConverter";
import { NaverTag } from "../../../components/common/NaverTag";
import { KakaoTag } from "../../../components/common/KakaoTag";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import MDHeader from "../../../components/common/Header";

const { Header, Content } = Layout;
const { Title } = Typography;
export enum LoginType {
  GOOGLE = "google",
  FACEBOOK = "facebook",
  APPLE = "apple",
  NAVER = "naver",
  KAKAO = "kakao",
}

interface USERTYPE {
  id: number;
  nickname: string;
  type: string;
  createdAt: string;
  phone: string;
  point: number;
  age: number;
  movingDistance: number;
  co2Reduction: number;
  plantedTree: number;
  email: string;
  count: number;
  isStop: number;
  expectedExpiredAmount: number;
}
//회원이름(닉네임), 이메일, 가입경로(구글, 페이스북), 탄소감축량, 가입일, 포인트
export interface GETUSERLISTTYPE {
  data: USERTYPE[];
  total: number;
}

export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");
  const test_user = params.get("test_user");
  const age = params.get("age");
  const type = params.get("type");

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<USERTYPE[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const userListColumn: ColumnsType<USERTYPE> = [
    {
      title: "연번",
      width: 80,
      dataIndex: "operation",
      render: (_, record, index) => `${(currentPage - 1) * 25 + (index + 1)}`,
    },
    {
      title: "이름",
      dataIndex: "nickname",
      sorter: true,
      defaultSortOrder:
        sort === "nickName" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "이메일",
      dataIndex: "email",
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "email" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "핸드폰",
      dataIndex: "phone",
      width: 150,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "phone" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "가입일",
      dataIndex: "createdAt",
      width: 150,
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
      render: (_, record) => timeConverter(record.createdAt).slice(0, 10),
    },
    {
      title: "가입경로",
      dataIndex: "type",
      filters: [
        {
          text: "kakao",
          value: "kakao",
        },
        {
          text: "google",
          value: "google",
        },
        {
          text: "apple",
          value: "apple",
        },
        {
          text: "naver",
          value: "naver",
        },
      ],
      filterMultiple: false,
      defaultFilteredValue: type === null ? [] : [type],
      sorter: true,
      defaultSortOrder:
        sort === "type" ? (order === "ASC" ? "ascend" : "descend") : null,
      render: (_, record) => {
        if (record.type === LoginType.GOOGLE) return <GoogleTag />;
        if (record.type === LoginType.FACEBOOK) return <FaceBookTag />;
        if (record.type === LoginType.APPLE) return <AppleTag />;
        if (record.type === LoginType.NAVER) return <NaverTag />;
        if (record.type === LoginType.KAKAO) return <KakaoTag />;
        return null;
      },
    },
    {
      title: "연령",
      dataIndex: "age",
      width: "8%",
      filters: [
        { text: "10대", value: 10 },
        { text: "20대", value: 20 },
        { text: "30대", value: 30 },
        { text: "40대", value: 40 },
        { text: "50대", value: 50 },
        { text: "60대", value: 60 },
        { text: "70대", value: 70 },
        { text: "80대", value: 80 },
      ],
      filterMultiple: false,
      defaultFilteredValue: age === null ? [] : [age],
    },
    {
      title: "이동거리",
      dataIndex: "moving_distance",
      width: "10%",
      sorter: true,
      defaultSortOrder:
        sort === "moving_distance"
          ? order === "ASC"
            ? "ascend"
            : "descend"
          : null,
      render: (_, record) =>
        `${Math.floor(record.movingDistance * 100) / 100} km`,
    },
    {
      title: "탄소 감축량",
      dataIndex: "co2_reduction",
      sorter: true,
      defaultSortOrder:
        sort === "co2_reduction"
          ? order === "ASC"
            ? "ascend"
            : "descend"
          : null,
      render: (_, record) =>
        `${Math.floor(record.co2Reduction * 100) / 100} kg`,
    },
    {
      title: "포인트",
      dataIndex: "point",
      sorter: true,
      defaultSortOrder:
        sort === "point" ? (order === "ASC" ? "ascend" : "descend") : null,
      render: (_, record) => record.point.toLocaleString("KR"),
    },

    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => (
        <Link
          to={LPPUrls.Admin.Users.Detail.url(record.id)}
          className="font-medium "
        >
          상세 페이지
        </Link>
      ),
    },
  ];

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.Users.Main.url(
        1,
        `${value}&${sort === null ? "sort=id" : `sort=${sort}`}&${
          order === null ? "order=DESC" : `order=${order}`
        }&${test_user === "true" ? "test_user=true" : `test_user=false`}${
          age === null ? "" : `&age=${age}`
        }${type === null ? "" : `&type=${type}`}`
      )
    );
    setCurrentPage(1);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Users.Main.url(
        page,
        `${keyword}&${sort === null ? "sort=id" : `sort=${sort}`}&${
          order === null ? "order=DESC" : `order=${order}`
        }&${test_user === "true" ? "test_user=true" : `test_user=false`}${
          age === null ? "" : `&age=${age}`
        }${type === null ? "" : `&type=${type}`}`
      )
    );
    setCurrentPage(page);
  };

  const handleChangeSort = (
    sort: string,
    order: string,
    filter: { type: string[]; age: string[] }
  ) => {
    navigate(
      LPPUrls.Admin.Users.Main.url(
        currentPage,
        `${keyword}&sort=${sort}&order=${order}&${
          test_user === "true" ? "test_user=true" : `test_user=false`
        }${filter.age.length === 0 ? "" : `&age=${filter.age[0]}`}${
          filter.type.length === 0 ? "" : `&type=${filter.type[0]}`
        }`
      )
    );
    //setCurrentPage(currentPage);
  };
  const handleFilterTestUser = (e: CheckboxChangeEvent) => {
    //setTestuserFilter(e.target.checked);

    navigate(
      LPPUrls.Admin.Users.Main.url(
        currentPage,
        `${keyword}&${sort === null ? "sort=id" : `sort=${sort}`}&${
          order === null ? "order=DESC" : `order=${order}`
        }&test_user=${e.target.checked}${age === null ? "" : `&age=${age}`}${
          type === null ? "" : `&type=${type}`
        }`
      )
    );
    setCurrentPage(currentPage);
  };

  const handleChange = (val: any, filter: any, sorter: any) => {
    const resetFilter = {
      age: filter.age === null ? [] : filter.age,
      type: filter.type === null ? [] : filter.type,
    };
    if (sorter.field !== undefined && sorter.order !== undefined) {
      const order = sorter.order === "ascend" ? "ASC" : "DESC";
      handleChangeSort(sorter.field, order, resetFilter);
    } else {
      handleChangeSort("id", "DESC", resetFilter);
    }
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<GETUSERLISTTYPE>(
      `/admin/users?${keyword === "" ? "" : `search=${keyword}&`}sort=${
        sort ?? "id"
      }&order=${order ?? "DESC"}&start=${currentPage}&perPage=25&test_user=${
        test_user === "true" ? "true" : "false"
      }${age === null ? "" : `&age=${age}`}${
        type === null ? "" : `&type=${type}`
      }`
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage, keyword, order, sort, test_user, age, type]);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      {/* <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>회원관리</Title>
      </Header> */}
      <MDHeader>
        <Title level={4}>회원관리</Title>
      </MDHeader>
      <Content className="m-6 bg-white">
        <Table
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={userListColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleChange(val, filter, sorter);
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space>
                <Title level={4}>회원 리스트</Title>
                <Checkbox
                  className="pb-2"
                  onChange={handleFilterTestUser}
                  defaultChecked={test_user === "true" ? true : false}
                >
                  테스터 필터
                </Checkbox>

                <a
                  href={`${process.env.REACT_APP_ENDPOINT}/admin/users/point/excel`}
                  // target="_blank"
                  download={"포인트 내역.xlsx"}
                >
                  <Button icon={<DownloadOutlined />} size="small">
                    포인트 내역 다운로드
                  </Button>
                </a>
                <a
                  href={`${
                    process.env.REACT_APP_ENDPOINT
                  }${"/admin/users/data/download"}`}
                  target="_blank"
                >
                  <Button
                    icon={<DownloadOutlined />}
                    size="small"
                    onClick={() => {}}
                  >
                    회원 정보 다운로드
                  </Button>
                </a>
              </Space>
              <Input.Search
                defaultValue={keyword ?? ""}
                style={{ width: 300 }}
                placeholder="회원의 이름 또는 이메일을 검색하세요"
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              />
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
};
