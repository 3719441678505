import { Tag } from "antd";
import { CrownOutlined } from "@ant-design/icons";

export const AdminTag = () => {
  return (
    <Tag color="gold" className="m-1" icon={<CrownOutlined />}>
      Admin
    </Tag>
  );
};
