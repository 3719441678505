import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";

import { DetailPage } from "./Detail";
import { TablePage } from "./Table";

export const ReportRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />
      <Route
        path={LPPUrls.Admin.Report.Detail.pathName}
        element={<DetailPage />}
      />
      {/* <Route
        path={LPPUrls.Admin.Report.Create.pathName}
        element={<CreatePage />}
      /> */}
      {/* <Route
        path={LPPUrls.Admin.Report.Update.pathName}
        element={<UpdatePage />}
      /> */}
      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};
