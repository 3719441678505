import {
  Layout,
  Typography,
  theme,
  Table,
  Pagination,
  PaginationProps,
  notification,
  Button,
  Space,
  Popconfirm,
} from "antd";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { timeConverter } from "../../../utils/timeConverter";

const { Header, Content } = Layout;
const { Title } = Typography;

interface Banner {
  id: number;
  productId: number;
  title: string;
  content: any[];
  image: string[];
  thumbnail?: string[];
  views: number;
  createdAt: string;
  updatedAt: string;
  isShow: number;
}
interface GETORDERLISTTYPE {
  data: Banner[];
  total: number;
}
export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<Banner[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const clubListColumn: ColumnsType<Banner> = [
    {
      title: "제목",
      dataIndex: "title",
    },
    {
      title: "생성일",
      dataIndex: "updatedAt",
      render: (_, record) => timeConverter(record.updatedAt),
    },
    {
      title: "조회수",
      dataIndex: "views",
    },

    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="배너 삭제"
            description="배너를 삭제하시겠습니까?"
            onConfirm={() => handleDeleteBanner(record.id)}
            okText="예"
            cancelText="아니오"
          >
            <Button type="text">삭제</Button>
          </Popconfirm>
          <Link
            to={LPPUrls.Admin.Banner.Detail.url(record.id)}
            className="font-medium"
          >
            상세 페이지
          </Link>
        </Space>
      ),
    },
  ];

  const handleDeleteBanner = (id: string | number) => {
    LPPAXIOS.delete(`/admin/banner/${id}`)
      .then(() => {
        api.success({
          message: "배너를 삭제하였습니다.",
        });
        setData((prev) => prev.filter((o) => o.id !== id));
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          api.error({
            message: "해당 배너를 찾을 수 없습니다.",
          });
        } else {
          api.error({
            message: "해당 배너를 찾을 수 없습니다.",
          });
        }
      });
  };

  const handleNavigateCreate = () => {
    navigate(LPPUrls.Admin.Banner.Create.pathName);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(LPPUrls.Admin.Banner.Main.url(page));
    setCurrentPage(page);
  };
  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<GETORDERLISTTYPE>(
      `/admin/banner?start=${currentPage}&perPage=25`
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: "배너 조회를 다시 시도해주시기 바랍니다.",
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage]);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>배너</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          pagination={false}
          columns={clubListColumn}
          dataSource={data}
          rowKey={(record) => record.id}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Title level={4}>배너 리스트</Title>
              <Button
                type="primary"
                onClick={handleNavigateCreate}
                disabled={data.length > 4 ? true : false}
              >
                배너 추가
              </Button>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
};
