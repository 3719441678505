import axios from "axios";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LPPAXIOS } from "../../../framework/api/core.api";

function RedirectPage() {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    console.log(code);
    LPPAXIOS.get(`admin/auth/naver/sign_in`, {
      params: {
        code: code,
      },
    })
      .then((resolve) => {
        const responseData = resolve.data.response;
        const jsonString = JSON.stringify(responseData); // 객체를 JSON 문자열로 변환
        localStorage.setItem("naverLogin", jsonString); // 로컬 스토리지에 저장
        console.log("@@naverLoginSuccess", resolve.data.response);
        // console.log(resolve.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [location]);

  return (
    <div className="naver-loading-container">
      <div className="naver-spinner"></div>
      <p>로그인 중...</p>
    </div>
  );
}
export default RedirectPage;
