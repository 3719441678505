import {
  Layout,
  Typography,
  theme,
  Table,
  Pagination,
  PaginationProps,
  notification,
  Button,
  Switch,
} from "antd";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";

const { Header, Content } = Layout;
const { Title } = Typography;

// 구매자명, 거래일시, 가격, 배송비, 상태, 결제방법
const stringToDate = (dateStr: string): Date => {
  const [year, month, day] = dateStr.split(".").map((num) => parseInt(num, 10));
  return new Date(year, month - 1, day);
};
interface Challenge {
  id: number;
  topic: string;
  blur: string;
  startDate: string;
  endDate: string;
  participant: number;
  views: number;
  isShow: number;
}
interface GETORDERLISTTYPE {
  data: Challenge[];
  total: number;
}
export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<Challenge[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const clubListColumn: ColumnsType<Challenge> = [
    {
      title: "주제",
      dataIndex: "topic",
      ellipsis: true,
      width: "30%",
    },
    {
      title: "시작일",
      dataIndex: "startDate",
    },
    {
      title: "마감일",
      dataIndex: "endDate",
    },

    {
      title: "참여자",
      dataIndex: "participant",
    },

    {
      title: "조회수",
      dataIndex: "views",
    },

    {
      title: "챌린지 표시",
      key: "isShow",
      // render: (_, record) => (

      //   <Space size="middle">
      //     <Popconfirm
      //       title="챌린지 삭제"
      //       description="챌린지를 삭제하시겠습니까?"
      //       onConfirm={() => handleDeleteChallenge(record.id)}
      //       okText="예"
      //       cancelText="아니오"
      //     >
      //       <Button type="text">삭제</Button>
      //     </Popconfirm>
      //     <Link
      //       to={LPPUrls.Admin.Challenge.Detail.url(record.id)}
      //       className="font-medium"
      //     >
      //       상세
      //     </Link>
      //   </Space>
      // ),
      render: (_, record) => {
        const nowDate = new Date();
        const startDate = stringToDate(record.startDate);
        const endDate = stringToDate(record.endDate);
        if (startDate <= nowDate && nowDate <= endDate) {
          return (
            <Switch
              checkedChildren="SHOW"
              unCheckedChildren="OFF"
              defaultChecked={record.isShow ? true : false}
              loading={loading}
              onChange={() => handleUpdateChallenge(record)}
            />
          );
        } else {
          return null;
        }
      },
    },
    // {
    //   title: "알림발송",
    //   dataIndex: "id",
    //   render: (id) => (
    //     <Button type="primary" onClick={() => onClickFCM(id)}>
    //       알림발송
    //     </Button>
    //   ),
    // },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => (
        <Link
          to={LPPUrls.Admin.Challenge.Detail.url(record.id)}
          className="font-medium"
        >
          상세 페이지
        </Link>
      ),
    },
  ];

  const handleNavigateCreate = () => {
    navigate(LPPUrls.Admin.Challenge.Create.pathName);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(LPPUrls.Admin.Notation.Main.url(page));
    setCurrentPage(page);
  };

  // const handleDeleteChallenge = async (id: string | number) => {
  //   setLoading(true);
  //   LPPAXIOS.delete(`/admin/challenge/${id}`)
  //     .then((resolve) => {
  //       api.success({
  //         message: "챌린지를 정상적으로 삭제하였습니다.",
  //       });

  //       setData((prev) => prev.filter((item) => item.id !== id));
  //     })
  //     .catch(function (error) {
  //       if (error.response.status === 400) {
  //         api.error({
  //           message: "잘못된 요청입니다.",
  //         });
  //       }
  //     })
  //     .finally(() => setLoading(false));
  // };
  const handleUpdateChallenge = async (record: Challenge) => {
    setLoading(true);
    let postShow: number;
    if (record.isShow === 1) {
      postShow = 0;
    } else {
      postShow = 1;
    }

    LPPAXIOS.put(`/admin/challenge/${record.id}`, {
      isShow: postShow,
    })
      .then((resolve) => {
        api.success({
          message: "챌린지를 정상적으로 수정하였습니다.",
        });
        setData((prev) =>
          prev.map((item) => {
            if (item.id === record.id) {
              item.isShow = postShow;
            }
            return item;
          })
        );

        // setData((prev) => prev.filter((item) => item.id !== id));
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          api.error({
            message: "잘못된 요청입니다.",
          });
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<GETORDERLISTTYPE>(
      `/admin/challenge?start=${currentPage}&perPage=25`
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage]);
  const onClickFCM = (id: number) => {
    LPPAXIOS.get(`/admin/fcm/challenge/${id}`)
      .then((resolve) => {
        api.success({ message: "알림 발송 완료" });
      })
      .catch(function (error) {
        if (error.response) {
          api.error({
            message: "알림 발송할 수 없습니다.",
            description: error.response.data.message ?? "",
          });
        }
      });
  };
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>챌린지</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          pagination={false}
          columns={clubListColumn}
          dataSource={data}
          rowKey={(record) => record.id}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Title level={4}>챌린지 리스트</Title>
              <Button type="primary" onClick={handleNavigateCreate}>
                챌린지 추가
              </Button>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
};
