import {
  Layout,
  Typography,
  theme,
  Table,
  Pagination,
  PaginationProps,
  notification,
  Button,
  Switch,
} from "antd";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { timeConverter } from "../../../utils/timeConverter";

const { Header, Content } = Layout;
const { Title } = Typography;

// 구매자명, 거래일시, 가격, 배송비, 상태, 결제방법

export interface Root {
  data: Daum[];
  total: number;
}

export interface Daum {
  id: number;
  title: string;
  content: any[];
  thumbnail: string[];
  views: number;
  createdAt: string;
}

interface Notation {
  id: number;
  title: string;
  content: any[];
  thumbnail: string[];
  views: number;
  createdAt: string;
  isSelect: number;
}
interface GETORDERLISTTYPE {
  data: Notation[];
  total: number;
}
export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<Notation[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const clubListColumn: ColumnsType<Notation> = [
    {
      title: "제목",
      dataIndex: "title",
    },
    {
      title: "생성일",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt),
    },

    {
      title: "조회수",
      dataIndex: "views",
    },
    {
      title: "홈 공지사항",
      dataIndex: "action",
      render: (_, record) => (
        <Switch
          checkedChildren="ON"
          unCheckedChildren="OFF"
          defaultChecked={record.isSelect ? true : false}
          loading={loading}
          onChange={() => handleUpdateHomeNotice(record)}
        />
      ),
    },
    {
      title: "알림발송",
      dataIndex: "id",
      render: (id) => (
        <Button type="primary" onClick={() => onClickFCM(id)}>
          알림발송
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => (
        <Link
          to={LPPUrls.Admin.Notation.Detail.url(record.id)}
          className="font-medium"
        >
          상세 페이지
        </Link>
      ),
    },
  ];

  const handleUpdateHomeNotice = (record: Notation) => {
    setLoading(true);
    let isSelect = record.isSelect === 0 ? 1 : 0;
    LPPAXIOS.put(`/admin/notice/home/${record.id}`)
      .then((res) => {
        setData((prev) =>
          prev.map((item) => {
            if (item.id === record.id) {
              item.isSelect = isSelect;
            }
            return item;
          })
        );
      })
      .catch((error) => {
        api.error({
          message: "요청을 수행할 수 없습니다.",
        });
      })
      .finally(() => setLoading(false));
  };

  const handleNavigateCreate = () => {
    navigate(LPPUrls.Admin.Notation.Create.pathName);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(LPPUrls.Admin.Notation.Main.url(page));
    setCurrentPage(page);
  };
  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<GETORDERLISTTYPE>(
      `/admin/notice?start=${currentPage}&perPage=25`
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage]);
  const onClickFCM = (id: number) => {
    LPPAXIOS.get(`/admin/fcm/notice/${id}`)
      .then((resolve) => {
        api.success({ message: "알림 발송 완료" });
      })
      .catch(function (error) {
        if (error.response) {
          api.error({
            message: "알림 발송할 수 없습니다.",
            description: error.response.data.message ?? "",
          });
        }
      });
  };
  return (
    <>
      {contextHolder}

      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>공지사항</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          loading={loading}
          pagination={false}
          columns={clubListColumn}
          dataSource={data}
          rowKey={(record) => record.id}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Title level={4}>공지사항 리스트</Title>
              <Button type="primary" onClick={handleNavigateCreate}>
                공지사항 추가
              </Button>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
};
