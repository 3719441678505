import { Layout, Menu, Typography } from "antd";
import type { MenuProps } from "antd";
import { useEffect, useState } from "react";
import {
  DashboardOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  ShoppingCartOutlined,
  ContactsOutlined,
  NotificationOutlined,
  QuestionCircleOutlined,
  AlertOutlined,
  BarChartOutlined,
  TrophyOutlined,
  SettingOutlined,
  PushpinOutlined,
  NodeIndexOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { LPPLOGO } from "../../assets";
import { LPPUrls } from "../../LPPUrls";
import { Link, useLocation } from "react-router-dom";
import { Content } from "antd/es/layout/layout";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  theme?: "light" | "dark"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    theme,
  } as MenuItem;
}

const { Sider } = Layout;

interface ILPPAdminCommonLayoutProps {
  children: React.ReactNode;
  role: "staff" | "admin";
}

export const LPPAdminCommonLayout = (props: ILPPAdminCommonLayoutProps) => {
  const location = useLocation();
  const { children, role } = props;
  const [current, setCurrent] = useState("");
  const [collapsed, setCollapsed] = useState(false);

  const items: MenuProps["items"] = [
    getItem(
      <Link to={LPPUrls.Admin.MyPage.Main.url()}>마이페이지</Link>,
      LPPUrls.Admin.MyPage.key,
      <UserOutlined />
    ),
    role === "admin"
      ? getItem(
          <Link to={LPPUrls.Admin.adminAccount.Main.url()}>관리자</Link>,
          LPPUrls.Admin.adminAccount.key,
          <DashboardOutlined />
        )
      : null,

    getItem(
      <Link to={LPPUrls.Admin.Users.Main.url()}>회원</Link>,
      LPPUrls.Admin.Users.key,
      <ContactsOutlined />
    ),
    getItem(
      <Link to={LPPUrls.Admin.Record.Main.url()}>기록</Link>,
      LPPUrls.Admin.Record.key,
      <BarChartOutlined />
    ),
    getItem("상품", "ClubManage", <AppstoreAddOutlined />, [
      getItem(
        <Link to={LPPUrls.Admin.Products.Main.url2()}>상품리스트</Link>,
        LPPUrls.Admin.Products.key
      ),
      getItem(
        <Link to={LPPUrls.Admin.Products.Create.url}>상품등록</Link>,
        LPPUrls.Admin.Products.Create.key
      ),
      getItem(
        <Link to={LPPUrls.Admin.Products.Category.url}>카테고리 관리</Link>,
        LPPUrls.Admin.Products.Category.key
      ),
    ]),
    getItem(
      <Link to={LPPUrls.Admin.Orders.Main.url()}>주문</Link>,
      LPPUrls.Admin.Orders.key,
      <ShoppingCartOutlined />
    ),
    getItem(
      <Link to={LPPUrls.Admin.Notation.Main.url()}>공지사항</Link>,
      LPPUrls.Admin.Notation.key,
      <NotificationOutlined />
    ),
    getItem(
      <Link to={LPPUrls.Admin.QnA.Main.url()}>Q&A</Link>,
      LPPUrls.Admin.QnA.key,
      <QuestionCircleOutlined />
    ),
    // getItem(
    //   <Link to={LPPUrls.Admin.Challenge.Main.url()}>챌린지</Link>,
    //   LPPUrls.Admin.Challenge.key,
    //   <TrophyOutlined />
    // ),
    getItem(
      <Link to={LPPUrls.Admin.Challenge.Main.url()}>챌린지</Link>,
      LPPUrls.Admin.Challenge.key,
      <TrophyOutlined />
    ),
    // getItem("챌린지", "Chanllenge", <TrophyOutlined />, [
    //   getItem(
    //     <Link to={LPPUrls.Admin.Challenge.Main.url()}>챌린지 목록</Link>,
    //     LPPUrls.Admin.Challenge.key
    //   ),
    //   getItem(
    //     <Link to={LPPUrls.Admin.Challenge.Record.url()}>회원 기록</Link>,
    //     LPPUrls.Admin.Challenge.Record.key
    //   ),
    // ]),
    getItem(
      <Link to={LPPUrls.Admin.Setting.Main.url()}>설정</Link>,
      LPPUrls.Admin.Setting.key,
      <SettingOutlined />
    ),
    getItem(
      <Link to={LPPUrls.Admin.Report.Main.url()}>신고</Link>,
      LPPUrls.Admin.Report.key,
      <AlertOutlined />
    ),
    getItem(
      <Link to={LPPUrls.Admin.Banner.Main.url()}>배너</Link>,
      LPPUrls.Admin.Banner.key,
      <PushpinOutlined />
    ),
    getItem(
      <Link to={LPPUrls.Admin.Rank.Main.url()}>랭킹</Link>,
      LPPUrls.Admin.Rank.key,
      <NodeIndexOutlined />
    ),
    getItem(
      <Link to={LPPUrls.Admin.ExpiredPoint.Main.url2()}>소멸 포인트</Link>,
      LPPUrls.Admin.ExpiredPoint.key,
      <DollarOutlined />
    ),
  ];

  useEffect(() => {
    setCurrent(location.pathname.split("/").slice(2, 4).join(""));
  }, [location.pathname]);

  return (
    <Layout hasSider>
      <Sider
        className="scrollbar-hide"
        style={{
          background: "#4D9226",
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        // collapsible
        // collapsed={collapsed}
        // onCollapse={(value) => setCollapsed(value)}
      >
        <div className="p-0 m-0 text-center">
          {process.env.REACT_APP_CHECK_ENV === "_dev_" && (
            <Typography.Title level={1} style={{ color: "white" }}>
              ..Develop..
            </Typography.Title>
          )}
          <LPPLOGO
            className={
              collapsed
                ? "text-right px-4 py-4 relative right-[300px]"
                : "text-right px-4 py-4 relative right-[0px]"
            }
            style={{ shapeRendering: "geometricPrecision" }}
          />
        </div>
        <Menu
          items={items}
          mode="inline"
          selectedKeys={[current]}
          theme={collapsed ? "dark" : "light"}
        />
      </Sider>
      <Layout className="bg-gray-50" style={{ marginLeft: 200 }}>
        <Content
          style={{
            overflow: "initial",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
