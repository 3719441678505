import { Button, Form, Input, message, Spin, Typography } from "antd";
import { LockOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { LPPUrls } from "../LPPUrls";
import { LPPLOGO } from "../assets";
import wallpaperflareJPG from "../assets/images/wallpaperflare.jpg";
import wallpaperflareWEBP from "../assets/images/wallpaperflare.webp";
import {
  LPPAXIOS,
  RequestPostAuth,
  ResponsePostAuth,
} from "../framework/api/core.api";
import { useState } from "react";

const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;

export const AdminLoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const onFinish = async (loginValue: RequestPostAuth) => {
    try {
      setLoading(true);
      const res = await LPPAXIOS.post<ResponsePostAuth>(
        "/admin/auth",
        loginValue
      );
      if (res.status === 200) {
        localStorage.setItem("lppuser", JSON.stringify(res.data));
        return navigate(LPPUrls.Admin.Root.pathName, { replace: true });
      }
      return messageApi.open({
        type: "error",
        content: "Fail to Login",
        duration: 2,
        style: {
          marginTop: "20vh",
        },
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Fail to Login",
        duration: 2,
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    // Ver.02
    <div className="relative flex w-screen h-screen overflow-hidden">
      <div
        className={
          loading
            ? "w-screen h-screen bg-white/50 absolute z-10"
            : "hidden w-screen h-screen bg-white/50 absolute z-10"
        }
      >
        <Spin
          className="relative left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
          spinning={loading}
          indicator={antIcon}
          size="large"
        ></Spin>
      </div>
      <picture className="hidden object-cover w-screen h-screen sm:block">
        <source type="image/webp" srcSet={wallpaperflareWEBP} />
        <source type="image/jpeg" srcSet={wallpaperflareJPG} />
        <img
          className="object-cover w-full h-full"
          src={wallpaperflareJPG}
          alt="background_picture"
        />
      </picture>
      <div className="absolute right-0 flex justify-end h-full min-w-min">
        <div className="hidden sm:block bg-gradient-to-l from-[#060207] h-full w-[120px]" />
        <div className="bg-[#060207] min-w-min h-full px-10 sm:pl-4 sm:pr-14 flex flex-col justify-center">
          <LPPLOGO />

          <Form
            layout="vertical"
            name="normal_login"
            className="w-[340px] font-bold"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              label={<span className="font-semibold text-white">username</span>}
              name="name"
              rules={[
                { required: true, message: "Please input your Username!" },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Username" />
            </Form.Item>
            <Form.Item
              label={<span className="font-semibold text-white">password</span>}
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            {/* <Form.Item>
              <Link className="float-right" to={LPPUrls.Auth.FindPassword.url}>
                Forgot password
              </Link>
            </Form.Item> */}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                className="font-bold"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {contextHolder}
    </div>

    // Ver.01
    // <div className="flex items-center justify-center w-screen h-screen">
    //   <div className="overflow-hidden rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg md:flex w-fit h-fit drop-shadow-md">
    //     <div className="md:bg-primary-colorPrimaryActive  md:block flex-col items-center px-2 py-5 md:w-[300px] md:h-[330px] text-white">
    //       <p className="px-1 text-right text-primary-colorPrimary md:text-white">
    //         for Admin & Staff
    //       </p>
    //       <LPPLOGO className="hidden md:block md:visible w-ful" />
    //       <LPPLOGOWITHCOLOR className="visible w-full md:hidden" />
    //     </div>
    //     <Card className="md:rounded-bl-none md:rounded-tl-none h-[330px]">

    //     </Card>
    //   </div>
    // </div>
  );
};
