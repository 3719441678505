import { useEffect, useRef, useState } from "react";

import { LoadingSpin } from "../../../components/common/Loading";
import { Layout, Typography, theme, Input, InputRef, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { LPPAXIOS } from "../../../framework/api/core.api";
import useNotification from "antd/es/notification/useNotification";
import { TweenOneGroup } from "rc-tween-one";

const { Header, Content } = Layout;
const { Title } = Typography;

export interface Category {
  id: number;
  name: string;
}
export const CategoryPage = () => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<Category[]>([]);
  const [api, contextHolder] = useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { token } = theme.useToken();
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);

  const handleClose = async (removedTag: Category) => {
    try {
      const newCategory = category.filter((tag) => tag.id !== removedTag.id);
      const targetCategory = category.find((tag) => tag.id === removedTag.id);

      const response = await LPPAXIOS.delete(
        `/admin/category/${targetCategory?.id}`
      );
      if (response.status === 200) {
        setCategory(newCategory);
      }
      return;
    } catch (error) {
      api.error({
        message: "해당 항목을 찾지 못했습니다.",
      });
    }
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = async () => {
    try {
      console.log("이거혹시 두번 던지냐?");
      if (
        inputValue &&
        category.map((item) => item.name).indexOf(inputValue) === -1
      ) {
        LPPAXIOS.post<{ id: number; name: string }>("/admin/category", {
          name: inputValue,
        }).then((resolve) => {
          setCategory([...category, { id: resolve.data.id, name: inputValue }]);
        });
        return;
      } else {
        return;
      }
    } catch (error) {
      api.error({
        message: "카테고리를 추가하지 못했습니다.",
      });
    } finally {
      setInputVisible(false);
      setInputValue("");
    }
  };

  const forMap = (tag: Category) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag.name}
      </Tag>
    );
    return (
      <span key={tag.id} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  const tagChild = category.map(forMap);

  const tagPlusStyle = {
    background: token.colorBgContainer,
    borderStyle: "dashed",
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<Category[]>("/admin/category")
      .then((resolve) => {
        setCategory(resolve.data);
      })
      .catch(function (error) {
        if (error.response) {
          api.error({
            message: "카테고리를 가져올수 없습니다.",
          });
        }
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (inputVisible) {
      console.log("이것도 두번 던지냐?");
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>카테고리 설정</Title>
      </Header>

      <Content className="p-3 m-6 bg-white">
        <div style={{ marginBottom: 16 }}>
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: "from",
              duration: 100,
            }}
            onEnd={(e) => {
              if (e.type === "appear" || e.type === "enter") {
                (e.target as any).style = "display: inline-block";
              }
            }}
            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
            appear={false}
          >
            {tagChild}
          </TweenOneGroup>
        </div>
        {inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={handleInputChange}
            // onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag onClick={showInput} style={tagPlusStyle}>
            <PlusOutlined /> New Tag
          </Tag>
        )}
      </Content>
    </>
  );
};
