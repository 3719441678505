import { useQuery } from "@tanstack/react-query";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { LPPAXIOS, ResponsePostAuth } from "../../framework/api/core.api";
import { LPPUrls } from "../../LPPUrls";
import { AdminAccountRoot } from "../../pages/AdminPages/adminAccount/Root";
import { BannerRoot } from "../../pages/AdminPages/Banner/Root";

import { ChallengeRoot } from "../../pages/AdminPages/Challenge/Root";

import { MyPageRoot } from "../../pages/AdminPages/MyPage/Root";
import { NotationRoot } from "../../pages/AdminPages/Notation/Root";
import { OrdersRoot } from "../../pages/AdminPages/Orders/Root";
import { ProductsRoot } from "../../pages/AdminPages/Products/Root";
import { QnAsRoot } from "../../pages/AdminPages/QnA/Root";
import { RankRoot } from "../../pages/AdminPages/rank/Root";
import { RecordRoot } from "../../pages/AdminPages/Record/Root";
import { ReportRoot } from "../../pages/AdminPages/Report/Root";
import { SettingRoot } from "../../pages/AdminPages/Setting/Root";

import { UsersRoot } from "../../pages/AdminPages/Users/Root";
import { LPPAdminCommonLayout } from "../Layout/LPPAdminCommonLayout";
import { ExpiredPointRoot } from "../../pages/AdminPages/ExpiredPoint/Root";

interface LoginSessionType {
  id: number;
  name: string;
  role: "staff" | "admin";
  createdAt: string;
  updatedAt: string;
  userId: number;
  enabled: boolean;
  type: "admin" | "staff";
}

export const LPPAdminRoutes = () => {
  const navigate = useNavigate();
  useQuery({
    queryKey: ["useSession"],
    queryFn: async () => {
      try {
        const res = await LPPAXIOS.get<LoginSessionType>("/admin/auth");
        if (res.status === 200) {
          localStorage.setItem("lppuser", JSON.stringify(res.data));
          return res.data;
        }
        return null;
      } catch (error) {
        localStorage.removeItem("lppuser");
        return navigate(LPPUrls.Auth.Root.pathName, { replace: true });
      }
    },
    refetchOnWindowFocus: false,
    refetchInterval: 6600 * 1000,
  });

  let localData = localStorage.getItem("lppuser");

  if (localData === null) {
    return <Navigate to={LPPUrls.Auth.Root.pathName} replace />;
  }
  const data: ResponsePostAuth = JSON.parse(localData);

  return (
    <LPPAdminCommonLayout role={data.role}>
      <Routes>
        <Route
          path={LPPUrls.Admin.MyPage.Main.pathName}
          element={<MyPageRoot />}
        />
        {data.role === "admin" && (
          <Route
            path={LPPUrls.Admin.adminAccount.Main.pathName}
            element={<AdminAccountRoot />}
          />
        )}
        <Route
          path={LPPUrls.Admin.Users.Main.pathName}
          element={<UsersRoot />}
        />

        {/* 상품 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Products.Main.pathName}
          element={<ProductsRoot />}
        />

        {/* 주문 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Orders.Main.pathName}
          element={<OrdersRoot />}
        />

        {/* 공지 사항 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Notation.Main.pathName}
          element={<NotationRoot />}
        />

        {/* 챌린지 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Challenge.Main.pathName}
          element={<ChallengeRoot />}
        />
        {/* Q&A 페이지 라우터 */}
        <Route path={LPPUrls.Admin.QnA.Main.pathName} element={<QnAsRoot />} />

        {/* 신고 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Report.Main.pathName}
          element={<ReportRoot />}
        />

        {/* 기록 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Record.Main.pathName}
          element={<RecordRoot />}
        />

        {/* 캠페인 & 후원하기 관리 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Setting.Main.pathName}
          element={<SettingRoot />}
        />

        {/* 배너 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Banner.Main.pathName}
          element={<BannerRoot />}
        />

        {/* 랭킹 페이지 라우터 */}
        <Route path={LPPUrls.Admin.Rank.Main.pathName} element={<RankRoot />} />

        {/* 소멸 포인트 라우터 */}
        <Route
          path={LPPUrls.Admin.ExpiredPoint.Main.pathName}
          element={<ExpiredPointRoot />}
        />

        <Route
          path={"*"}
          element={<Navigate to={LPPUrls.Admin.MyPage.Main.url()} />}
        />
      </Routes>
    </LPPAdminCommonLayout>
  );
};
