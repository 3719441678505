import {
  Layout,
  Typography,
  theme,
  Table,
  Pagination,
  PaginationProps,
  Button,
  Space,
  Input,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import useNotification from "antd/es/notification/useNotification";
import { HContent } from "../../../components/common/HContent";
import { timeConverter } from "../../../utils/timeConverter";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";

const { Header } = Layout;
const { Title } = Typography;

//상품명, 판매가격, 할인가격, 대표이미지, 등록일, 재고, 총판매량, 상태
//상태는 무엇을 의미하나??
interface RESPONSETYPE {
  data: RECORD[];
  total: number;
}

interface RECORD {
  nickName: string;
  recordId: string;
  moving_distance: number;
  co2_reduction: number;
  planted_tree: number;
  moving_time: number;
  image: string[];
  moving_type: string;
  createdAt: string;
}

export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get("keyword");
  const sort = params.get("sort");
  const order = params.get("order");

  //const [order, setOrder] = useState(sort !== null ? sort : "createdAt");
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = useNotification();
  const [data, setData] = useState<RECORD[]>([]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const clubListColumn: ColumnsType<RECORD> = [
    {
      title: "연번",
      dataIndex: "operation",
      render: (_, record, index) => `${(currentPage - 1) * 25 + (index + 1)}`,
    },
    { title: "닉네임", dataIndex: "nickName", width: "10%", sorter: true },
    {
      title: "이동수단",
      dataIndex: "moving_type",
      render: (_, record) => {
        if (record.moving_type === "walking") return "걷기";
        if (record.moving_type === "bus") return "버스";
        if (record.moving_type === "bicycle") return "자전거";
        return "걷기";
      },
    },
    {
      title: "이동거리",
      dataIndex: "moving_distance",
      sorter: true,
      render: (_, record) =>
        `${Math.floor(record.moving_distance * 100) / 100}km`,
    },
    {
      title: "탄소감축량",
      sorter: true,
      dataIndex: "co2_reduction",

      render: (_, record) =>
        `${Math.floor(record.co2_reduction * 100) / 100} kg/co2`,
    },
    {
      title: "심은 소나무",
      dataIndex: "planted_tree",
      render: (_, record) =>
        `${Math.floor(record.planted_tree * 100) / 100}그루`,
    },
    {
      title: "기록 시간",
      sorter: true,
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt),
    },
    {
      title: (
        <a
          href={`${process.env.REACT_APP_ENDPOINT}${"/admin/record/download"}`}
          target="_blank"
        >
          <Button icon={<DownloadOutlined />} size="small" onClick={() => {}}>
            기록 정보 다운로드
          </Button>
        </a>
      ),
      dataIndex: "operation",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() =>
            navigate(LPPUrls.Admin.Record.Detail.url(record.recordId), {
              state: record,
            })
          }
        >
          상세 페이지
        </Button>
        // <Link
        //   to={LPPUrls.Admin.QnA.Update.url(record.id)}
        //   className="font-medium"
        // >
        //   수정
        // </Link>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(
      `/admin/record?${keyword === "" ? "" : `search=${keyword}&`}sort=${
        sort ?? "createdAt"
      }&order=${order ?? "DESC"}&start=${currentPage}&perPage=25
      `
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) =>
        api.error({
          message: "이동 기록 목록을 불러오지 못했습니다.",
        })
      )
      .finally(() => setLoading(false));
  }, [currentPage, api, sort, order, keyword]);

  // const handleOnSearchFilter = (value: string) => {
  //   navigate(LPPUrls.Admin.QnA.Main.url(1, value));
  //   setCurrentPage(1);
  // };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Record.Main.url(
        page,
        `${keyword}&${sort === null ? "sort=createdAt" : `sort=${sort}`}&${
          order === null ? "order=DESC" : `order=${order}`
        }`
      )
    );
    setCurrentPage(page);
  };
  // const handleChangeOrder = (value: string) => {
  //   setOrder(value);
  //   navigate(
  //     LPPUrls.Admin.QnA.Main.url(
  //       currentPage,
  //       `${keyword ?? ""}&sort=${value}`
  //     )
  //   );
  // };

  const handleChangeSort = (sort: string, order: string) => {
    navigate(
      LPPUrls.Admin.Record.Main.url(
        currentPage,
        `${keyword}&sort=${sort}&order=${order}`
      )
    );
    //setCurrentPage(currentPage);
  };
  const handleChange = (val: any, sorter: any) => {
    if (sorter.field !== undefined && sorter.order !== undefined) {
      const order = sorter.order === "ascend" ? "ASC" : "DESC";
      handleChangeSort(sorter.field, order);
    } else {
      handleChangeSort("createdAt", "DESC");
    }
  };
  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.Record.Main.url(
        1,
        `${value}&${sort === null ? "sort=createdAt" : `sort=${sort}`}&${
          order === null ? "order=DESC" : `order=${order}`
        }`
      )
    );
    setCurrentPage(1);
  };

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>Record</Title>
      </Header>
      <HContent>
        <Table
          pagination={false}
          rowKey={(render) => render.recordId}
          dataSource={data}
          columns={clubListColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleChange(val, sorter);
          }}
          title={() => (
            <Space>
              <Input.Search
                defaultValue={keyword ?? ""}
                style={{ width: 300 }}
                placeholder="회원의 이름을 검색하세요"
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              />
            </Space>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </HContent>
      {contextHolder}
    </>
  );
};
