import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Divider,
  Image,
  Space,
  Result,
  Button,
  Popconfirm,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios, { AxiosError } from "axios";

import { HContent } from "../../../components/common/HContent";
import useNotification from "antd/es/notification/useNotification";
import { urlRegex } from "../../../utils/converter";
import { timeConverter } from "../../../utils/timeConverter";

const { Header } = Layout;
const { Title } = Typography;

export interface Content {
  id: string;
  data: Data;
  type: string;
}

export interface Data {
  link: string;
  text: string;
}

interface RESPONSETYPE {
  id: number;
  title: string;
  content: any[];
  thumbnail: string;
  views: number;
  createdAt: string;
}

export const DetailPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RESPONSETYPE>();
  const [error, setError] = useState<AxiosError | Error | null>(null);

  const [api, contextHolder] = useNotification();

  const { id } = useParams();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleDeleteBanner = () => {
    LPPAXIOS.delete(`/admin/banner/${id}`)
      .then(() => {
        api.success({
          message: "배너를 삭제하였습니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Banner.Main.url());
        }, 500);
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          api.error({
            message: "해당 배너를 찾을 수 없습니다.",
          });
        } else {
          api.error({
            message: "해당 배너를 찾을 수 없습니다.",
          });
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(`/admin/banner/${id}`)
      .then((res) => {
        const editBlocks = res.data.content;
        for (let i = 0; i < editBlocks.length; i++) {
          if (editBlocks[i].type === "image") {
            editBlocks[
              i
            ].data.file.url = `${process.env.REACT_APP_IMG_ENDPOINT}/${editBlocks[i].data.file.url}`;
          }
        }
        setData({ ...res.data, content: editBlocks });
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          setError(error);
        }
      })
      .finally(() => setLoading(false));
  }, [id, api]);

  if (error !== null)
    return (
      <Result
        title={error?.message}
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary">Back Home</Button>}
      />
    );
  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>배너</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Banner.Main.url()}>배너</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>배너 상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Typography>
          <Typography.Title level={4}>{data?.title}</Typography.Title>
          <Space className="flex justify-between">
            <Space>
              <Typography.Text>
                작성일: {timeConverter(data?.createdAt ?? "0")}
              </Typography.Text>
              <Typography.Text>조회수: {data?.views}</Typography.Text>
            </Space>
            <Space size="small">
              <Button
                type="text"
                onClick={() =>
                  navigate(LPPUrls.Admin.Banner.Update.url(id!), {
                    state: data,
                  })
                }
              >
                수정
              </Button>
              |
              <Popconfirm
                title="배너 삭제"
                description="배너를 삭제하시겠습니까?"
                onConfirm={handleDeleteBanner}
                okText="예"
                cancelText="아니오"
              >
                <Button type="text">삭제</Button>
              </Popconfirm>
            </Space>
          </Space>

          <Divider orientation="left">배너 이미지</Divider>
          <Image
            width={300}
            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.thumbnail}`}
          />
          <Divider />
          <Typography>
            {data?.content.map((item, index) => {
              if (item.type === "image") {
                return (
                  <div key={item.id} className="max-w-md">
                    <Image src={`${item.data.file.url}`} />
                  </div>
                );
              } else {
                if (urlRegex.test(item.data.text)) {
                  return (
                    <p>
                      <a href={item.data.text} target="_blank">
                        {item.data.text}
                      </a>
                    </p>
                  );
                } else {
                  if (item.data.text === "<br>") {
                    return <br key={index} />;
                  } else {
                    return (
                      <Typography.Paragraph
                        key={`content-${index}`}
                        className="whitespace-pre-line"
                      >
                        {item.data.text}
                      </Typography.Paragraph>
                    );
                  }
                }
              }
            })}
          </Typography>
        </Typography>
      </HContent>
      {contextHolder}
    </>
  );
};
