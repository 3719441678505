import {
  Button,
  Form,
  Input,
  Layout,
  notification,
  theme,
  Typography,
} from "antd";

import { useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";

import { HContent } from "../../../components/common/HContent";
import { useNavigate } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";

// interface GETUPLOADURL {
//   path: string;
//   url: string;
// }

// interface POSTPRODUCTTYPE {
//   name: string;
//   image: string[];
//   contents_image: string;
//   contents_description: string;
//   price: number;
//   discount: number;
//   description: string;
//   origin: string;
//   brand: string;
//   stock: number;
// }

// interface PARAGRAPH {
//   text: string;
// }
// interface FILE64 {
//   caption: string;
//   stretched: boolean;
//   withBackground: boolean;
//   withBorder: boolean;
//   file: {
//     url: string;
//     mimetype: string;
//     origin: File;
//   };
// }
// interface Block {
//   id: string;
//   type: "image" | "paragraph";
//   data: FILE64 | PARAGRAPH;
// }
// interface EditorSave {
//   time: number;
//   version: string;
//   blocks: Block[];
// }

const { Header } = Layout;
const { Title } = Typography;

export const CreatePage = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState(null);
  // const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // async function uploadImgToS3(
  //   file: ArrayBuffer,
  //   type: string,
  //   url: string,
  //   path: string
  // ) {
  //   const res = await axios.put(url, file, {
  //     headers: {
  //       "Content-Type": type,
  //     },
  //   });
  //   if (res.status === 200) {
  //     return path;
  //   }
  //   return "";
  // }

  // const handleUploadCompMultiFiles = async () => {
  //   if (uploadFileList.length === 0) return;
  //   try {
  //     const paths: string[] = [];
  //     for (let i = 0; i < uploadFileList.length; i++) {
  //       console.log(uploadFileList[i].name);
  //       const fileBuffer = await uploadFileList[i].originFileObj?.arrayBuffer();
  //       const res = await LPPAXIOS.get<GETUPLOADURL[]>(
  //         `admin/files/upload?type=images&mimeType=${uploadFileList[i].type}&imageUploadTarget=product&num=1`
  //       );
  //       if (res.status === 200) {
  //         const path = await uploadImgToS3(
  //           fileBuffer!,
  //           uploadFileList[i].type!,
  //           res.data[0].url,
  //           res.data[0].path
  //         );
  //         paths.push(path);
  //       }
  //     }

  //     return paths;
  //   } catch (error) {
  //   } finally {
  //   }
  // };

  // const handleConvertEditorBlocks = async () => {
  //   if (!data) return;
  //   const convertArray: any[] = [];
  //   const { blocks } = data as EditorSave;
  //   for (let i = 0; i < blocks.length; i++) {
  //     if (blocks[i].type === "image") {
  //       const file64 = blocks[i].data as FILE64;
  //       const arrayBuffer = await file64.file.origin.arrayBuffer();
  //       const res = await LPPAXIOS.get<GETUPLOADURL[]>(
  //         `admin/files/upload?type=images&mimeType=${file64.file.mimetype}&imageUploadTarget=product&num=1`
  //       );
  //       if (res.status === 200) {
  //         const path = await uploadImgToS3(
  //           arrayBuffer,
  //           file64.file.mimetype,
  //           res.data[0].url,
  //           res.data[0].path
  //         );
  //         convertArray.push({
  //           id: blocks[i].id,
  //           type: "image",
  //           data: {
  //             file: {
  //               url: path,
  //             },
  //           },
  //           caption: "",
  //           withBorder: true,
  //           stretched: false,
  //           withBackground: false,
  //         });
  //       }
  //     } else {
  //       convertArray.push(blocks[i]);
  //     }
  //   }
  //   return convertArray;
  // };
  // const handlePostProduct = async (value: POSTPRODUCTTYPE) => {
  //   if (!data) {
  //     api.error({
  //       message: "상품 상세 설명 등록은 필수입니다.",
  //       description: "이미지 또는 설명을 작성하시기 바랍니다.",
  //     });
  //     return;
  //   }

  //   if (uploadFileList.length === 0) {
  //     api.error({
  //       message: "상품 이미지 등록은 필수입니다.",
  //       description: "1개 이상의 이미지를 등록해주세요",
  //     });
  //     return;
  //   }
  //   try {
  //     setLoading(true);
  //     const images = (await handleUploadCompMultiFiles()) ?? [];
  //     const descriptionEditorData = (await handleConvertEditorBlocks()) ?? [];

  //     const formData = {
  //       ...value,
  //       discount: Number(value.discount),
  //       price: Number(value.price),
  //       stock: Number(value.stock),
  //       image: images,
  //       description: descriptionEditorData,
  //     };
  //     console.log(formData);
  //     const res = await LPPAXIOS.post(`/admin/product`, formData);
  //     if (res.status === 200) {
  //       api.success({
  //         message: `상품 등록을 성공하였습니다.`,
  //         description: `product load success`,
  //       });
  //       setTimeout(() => {
  //         navigate(LPPUrls.Admin.Products.Main.url());
  //       }, 500);
  //     }
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       api.error({
  //         message: `Notification ${error.code}`,
  //         description: `${error.message}`,
  //       });
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handlePostQuestion = async (value: any) => {
    setLoading(true);
    LPPAXIOS.post(`/admin/question`, {
      question: value.question.trim(),
      answer: value.answer.trim(),
    })
      .then((value) => {
        api.success({
          message: "Q&A가 정상적으로 등록되었습니다.",
        });
        setTimeout(() => navigate(LPPUrls.Admin.QnA.Main.url()), 500);
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          api.error({
            message: "잘못된 요청입니다. 다시 시도해주시기 바랍니다.",
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="drop-shadow-sm"
      >
        <Title>Q&A 등록</Title>
      </Header>
      <HContent>
        <Form onFinish={handlePostQuestion}>
          <Form.Item
            label="질문"
            name="question"
            rules={[
              { required: true, message: "질문을 입력하세요." },
              { min: 10, message: "질문은 최소 10자 이상입니다." },
              { max: 1000, message: "질문은 최대 1000자 이하입니다." },
            ]}
          >
            <Input showCount maxLength={20} onInput={preventSpaceBar} />
          </Form.Item>
          <Form.Item
            label="답변"
            name="answer"
            rules={[
              { required: true, message: "답변을 입력하세요." },
              { min: 10, message: "답변은 최소 10자 이상입니다." },
              { max: 6000, message: "답변은 최대 6000자 이하입니다." },
            ]}
          >
            <Input.TextArea
              showCount
              maxLength={6000}
              onInput={preventSpaceBar}
              autoSize={{ minRows: 15, maxRows: 30 }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              등록
            </Button>
          </Form.Item>
        </Form>
      </HContent>
    </>
  );
};
