import {
  Button,
  Divider,
  Form,
  Input,
  Layout,
  notification,
  Select,
  Space,
  theme,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios from "axios";
import Editor from "../../../components/common/Editor/Editor";
import {
  onInputOnlyNumber,
  onPressOnlyNumber,
  preventSpaceBar,
} from "../../../utils/inputOnlyNumber";
import { useLocation, useNavigate } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import { Category } from "./Category";

interface GETUPLOADURL {
  path: string;
  url: string;
}

interface POSTPRODUCTTYPE {
  name: string;
  image: string[];
  contents_image: string;
  contents_description: string;
  price: number;
  discount: number;
  description: string;
  origin: string;
  brand: string;
  stock: number;
  category: number;
}

interface PARAGRAPH {
  text: string;
}
interface FILE64 {
  caption: string;
  stretched: boolean;
  withBackground: boolean;
  withBorder: boolean;
  file: {
    url: string;
    mimetype: string;
    origin: File;
  };
}

const { Header, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

export const UpdatePage = () => {
  const location = useLocation();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(location.state.description);
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
  const [category, setCategory] = useState<Category[]>([]);
  const [api, contextHolder] = notification.useNotification();

  async function uploadImgToS3(
    file: ArrayBuffer,
    type: string,
    url: string,
    path: string
  ) {
    const res = await axios.put(url, file, {
      headers: {
        "Content-Type": type,
      },
    });
    if (res.status === 200) {
      return path;
    }
    return "";
  }

  const handleUploadCompMultiFiles = async () => {
    try {
      if (uploadFileList.length === 0) return;
      const paths: string[] = [];
      for (let i = 0; i < uploadFileList.length; i++) {
        if (uploadFileList[i].uid.includes("done file")) {
          paths.push(
            uploadFileList[i].url!.replace(
              `${process.env.REACT_APP_IMG_ENDPOINT}/`,
              ""
            )
          );
        } else {
          const fileBuffer = await uploadFileList[
            i
          ].originFileObj?.arrayBuffer();
          const res = await LPPAXIOS.get<GETUPLOADURL[]>(
            `admin/files/upload?type=images&mimeType=${uploadFileList[i].type}&imageUploadTarget=product&num=1`
          );
          if (res.status === 200) {
            const path = await uploadImgToS3(
              fileBuffer!,
              uploadFileList[i].type!,
              res.data[0].url,
              res.data[0].path
            );
            paths.push(path);
          }
        }
      }

      return paths;
    } catch (error) {
    } finally {
    }
  };

  const handleConvertEditorBlocks = async () => {
    if (!data) return;

    const convertArray: any[] = [];
    const { blocks } = data;

    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].type === "image" && !blocks[i].id.includes("done file")) {
        const file64 = blocks[i].data as FILE64;
        const arrayBuffer = await file64.file.origin.arrayBuffer();
        const res = await LPPAXIOS.get<GETUPLOADURL[]>(
          `admin/files/upload?type=images&mimeType=${file64.file.mimetype}&imageUploadTarget=product&num=1`
        );
        if (res.status === 200) {
          const path = await uploadImgToS3(
            arrayBuffer,
            file64.file.mimetype,
            res.data[0].url,
            res.data[0].path
          );
          convertArray.push({
            id: blocks[i].id,
            type: "image",
            data: {
              file: {
                url: path,
              },
            },
            caption: "",
            withBorder: true,
            stretched: false,
            withBackground: false,
          });
        }
      } else if (
        blocks[i].type === "image" &&
        blocks[i].id.includes("done file")
      ) {
        blocks[i].data.file.url = blocks[i].data.file.url.replace(
          `${process.env.REACT_APP_IMG_ENDPOINT}/`,
          ""
        );
        convertArray.push(blocks[i]);
      } else {
        const paragraph = blocks[i].data as PARAGRAPH;
        const text = paragraph.text
          .replaceAll("&nbsp;", "")
          .replaceAll("<br>", "");
        convertArray.push({
          id: blocks[i].id,
          type: blocks[i].type,
          data: {
            text: text,
          },
        });
      }
    }

    return convertArray;
  };
  const handlePostProduct = async (value: POSTPRODUCTTYPE) => {
    if (!data) {
      api.error({
        message: "상품 상세 설명 등록은 필수입니다.",
        description: "이미지 또는 설명을 작성하시기 바랍니다.",
      });
      return;
    }

    if (uploadFileList.length === 0) {
      api.error({
        message: "상품 이미지 등록은 필수입니다.",
        description: "1개 이상의 이미지를 등록해주세요",
      });
      return;
    }
    if (Number(value.price) <= Number(value.discount)) {
      api.error({
        message: "할인가격이 상품가격보다 높거나 같습니다.",
        description: "할인가격을 상품보다 작게 설정해주세요",
      });
      return;
    }
    try {
      setLoading(true);

      const images = (await handleUploadCompMultiFiles()) ?? [];

      const descriptionEditorData = (await handleConvertEditorBlocks()) ?? [];

      const formData = {
        ...value,
        image: images,
        description: descriptionEditorData,
        categoryId: Number(value.category),
      };

      const res = await LPPAXIOS.put(
        `/admin/product/${location.state.id}`,
        formData
      );

      if (res.status === 200) {
        api.success({
          message: `상품 수정을 성공하였습니다.`,
          description: `product load success`,
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Products.Main.url());
        }, 500);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: `상품 수정을 실패하였습니다.`,
          description: `${error.response?.data.message}`,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const startEdit = () => {
      const imageFileList = location.state.image;
      for (let i = 0; i < imageFileList.length; i++) {
        const previewFile: UploadFile = {
          uid: `done file ${i}`,
          name: imageFileList[i],
          status: "done",
          url: `${process.env.REACT_APP_IMG_ENDPOINT}/${imageFileList[i]}`,
        };
        setUploadFileList((prev) => [...prev, previewFile]);
      }
      let findEditorBlocks = location.state.description;
      for (let i = 0; i < findEditorBlocks.length; i++) {
        findEditorBlocks[i].id = `done file ${i}`;
      }
      setData({ blocks: findEditorBlocks });
      setLoading(true);
      LPPAXIOS.get<Category[]>("/admin/category")
        .then((resolve) => {
          setCategory(resolve.data);
        })
        .catch(function (error) {
          if (error.response) {
            api.error({
              message: "카테고리를 가져올수 없습니다.",
            });
          }
        })
        .finally(() => setLoading(false));
    };
    startEdit();
  }, []);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="drop-shadow-sm"
      >
        <Title>상품 수정</Title>
      </Header>
      <Content className="m-6">
        <Form
          onFinish={handlePostProduct}
          style={{ width: 800 }}
          initialValues={{ ...location.state, stock: location.state.stock }}
        >
          <Form.Item label="상품 이미지">
            <Upload
              fileList={uploadFileList}
              accept="image/*"
              listType="picture-card"
              showUploadList={{ showPreviewIcon: false }}
              customRequest={({ onSuccess }) => {
                const res = "Ok";
                setTimeout(() => {
                  onSuccess!(res);
                }, 600);
              }}
              onChange={({ fileList }) => {
                setUploadFileList(fileList);
              }}
            >
              {uploadFileList.length >= 4 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Space>
            <Form.Item
              label="상품명"
              name="name"
              rules={[
                { required: true, message: "상품명은 입력은 필수입니다." },
                { min: 2 },
              ]}
            >
              <Input showCount maxLength={100} onInput={preventSpaceBar} />
            </Form.Item>
            <Form.Item
              label="원산지"
              name="origin"
              rules={[
                { required: true, message: "원산지 입력은 필수입니다." },
                { min: 2 },
              ]}
            >
              <Input showCount maxLength={12} onInput={preventSpaceBar} />
            </Form.Item>
            <Form.Item
              label="브랜드"
              name="brand"
              rules={[
                { required: true, message: "브랜드 입력은 필수입니다." },
                { min: 2 },
              ]}
            >
              <Input showCount maxLength={30} onInput={preventSpaceBar} />
            </Form.Item>
          </Space>

          <Space>
            <Form.Item
              label="가격"
              name="price"
              rules={[{ required: true, message: "가격 입력은 필수입니다." }]}
            >
              <Input
                maxLength={30}
                onKeyPress={onPressOnlyNumber}
                onInput={onInputOnlyNumber}
              />
            </Form.Item>
            <Form.Item label="할인가격" name="discount">
              <Input
                maxLength={10}
                onKeyPress={onPressOnlyNumber}
                onInput={onInputOnlyNumber}
              />
            </Form.Item>
            <Form.Item
              label="재고"
              name="stock"
              rules={[{ required: true, message: "재고 입력은 필수입니다." }]}
            >
              <Input
                showCount
                maxLength={10}
                onKeyPress={onPressOnlyNumber}
                onInput={onInputOnlyNumber}
              />
            </Form.Item>
          </Space>
          <Form.Item
            label="카테고리"
            name="category"
            rules={[{ required: true, message: "카테고리 선택은 필수입니다." }]}
          >
            <Select
              placeholder="상품카테고리를 선택해주세요"
              allowClear
              defaultValue={category.find(
                (o) => o.name === location.state.category
              )}
            >
              {category.map((item, index) => (
                <Option key={`${item.name}-${item.id}`} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="상품 설명"
            name="contents_description"
            rules={[
              { required: true, message: "상품 설명 입력은 필수입니다." },
              { min: 5 },
            ]}
          >
            <Input
              showCount
              maxLength={100}
              onChange={preventSpaceBar}
              onInput={preventSpaceBar}
            />
          </Form.Item>
          <Divider />
          <Typography.Title level={4}>상품 상세 설명</Typography.Title>
          <div className="m-4 w-[100%] p-[5%] bg-[#f4f5f9]">
            <Editor data={{ blocks: data }} setData={setData} />
          </div>
          <Form.Item>
            <div className="flex justify-center w-full">
              <Button type="primary" htmlType="submit">
                상품 수정
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Content>
    </>
  );
};
