import { Link, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Descriptions,
  Result,
  Card,
  Space,
  Image,
  Divider,
  Button,
  Modal,
  Form,
  Input,
  notification,
} from "antd";
import { LPPUrls } from "../../../LPPUrls";
import { DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LoadingSpin } from "../../../components/common/Loading";
import { HContent } from "../../../components/common/HContent";
import {
  onInputOnlyNumber,
  onPressOnlyNumber,
} from "../../../utils/inputOnlyNumber";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const { Header } = Layout;
const { Title } = Typography;

export interface OrderList {
  id: number;
  name: string;
  quantity: number;
  thumbnail: string[];
}

export interface Data {
  order_number: number;
  invoice_number: number;
  payment_method: string;
  sender_phone: string;
  recipient_name: string;
  recipient_address: string;
  recipient_phone: string;
  receiving_location: string;
  way_to_enter: string;
  nickname: string;
  user_id: number;
  delivery_fee: number;
  total_price: number;
  used_point: number;
  total_payment: number;
  total_discount: number;
  createdAt: string;
  delivery_status: string;
  order_list: OrderList[];

  // 배송요청사항
  // 받으실분 성함
}

export interface RESPONSETYPE {
  data: Data;
}
export const DetailPage = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState<Data>({
    order_number: 0,
    invoice_number: 0,
    payment_method: "string",
    sender_phone: "string",
    recipient_name: "string",
    recipient_address: "string",
    recipient_phone: "string",
    receiving_location: "string",
    way_to_enter: "string",
    nickname: "string",
    user_id: 0,
    delivery_fee: 0,
    total_price: 0,
    used_point: 0,
    total_payment: 0,
    total_discount: 0,
    createdAt: "string",
    delivery_status: "string",
    order_list: [],
  });
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = notification.useNotification();
  // const [edit, setEdit] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const postInvoiceNumber = () => {
    setOpen(true);
  };

  const updateInvoiceNumber = () => {
    setOpenUpdate(true);
  };

  const handleAddInvoiceNumber = async (value: any) => {
    //try {
    setLoading(true);
    //const response =
    await LPPAXIOS.put(`/admin/order/${id}`, {
      invoice_number: Number(value.invoice_number),
    })
      .then((resolve) => {
        if (resolve.status === 200) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          api.error({
            message: "송장번호가 올바르지 않습니다.",
          });
        } else if (error.response.status === 404) {
          api.error({
            message: "주문상품을 찾을 수 없습니다.",
          });
        }
      })
      .finally(() => setLoading(false));
  };

  // const handleOnKeyPress = (e: any) => {
  //   if (e.key === "Enter") {
  //     if (
  //       e.currentTarget.value === null ||
  //       e.currentTarget.value === undefined ||
  //       e.currentTarget.value === ""
  //     ) {
  //       // Enter 입력이 되면 클릭 이벤트 실행
  //       api.error({
  //         message: "올바른 값을 입력하세요",
  //       });
  //     } else {
  //       setEdit(false);
  //       handleAddInvoiceNumber({ invoice_number: e.currentTarget.value });
  //     }
  //   }
  // };

  // 명진님 API 문제 해결이 필요해보임.
  const handleCancelOrder = async () => {
    setLoading(true);
    await LPPAXIOS.post(`/admin/order/${id}`, { userId: data.user_id })
      .then(() => {
        api.success({
          message: "주문을 취소하였습니다.",
        });
        setTimeout(() => {
          window.location.reload();
        }, 600);
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          api.error({
            message: "잘못된 요청입니다.",
            description: `${error.response.status}Error`,
          });
        } else if (error.response.status === 401) {
          api.error({
            message: "주문코드가 필요합니다.",
            description: `${error.response.status}Error`,
          });
        } else if (error.response.status === 404) {
          api.error({
            message: "해당주문을 찾을 수 없습니다.",
            description: `${error.response.status}Error`,
          });
        } else if (error.response.status === 409) {
          api.error({
            message: "주문을 취소하지 못했습니다.",
            description: `${error.response.status}Error`,
          });
        } else if (error.response.status === 441) {
          api.error({
            message: "이미 전액 취소된 주문입니다.",
            description: `${error.response.status}Error`,
          });
        } else if (error.response.status === 442) {
          api.error({
            message: "취소할 결제건이 존재하지 않습니다.",
            description: `${error.response.status}Error`,
          });
        } else if (error.response.status === 443) {
          api.error({
            message:
              "결제취소에 실패하였습니다. 해당거래 취소실패(기취소성공) : 전화 문의(1661-0808)",
            description: `${error.response.status}Error`,
          });
        } else if (error.response.status === 445) {
          api.error({
            message: "이미 배송중인 주문입니다.",
            description: `${error.response.status}Error`,
          });
        } else if (error.response.status === 490) {
          api.error({
            message: "No_Card",
            description: `${error.response.status}Error`,
          });
        } else if (error.response.status === 500) {
          api.error({
            message: "Server Error",
            description: `${error.response.status}Error`,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("My Sheet"); // sheet 이름이 My Sheet

    // sheet 데이터 설정
    worksheet.columns = [
      { header: "주문 코드", key: "order_number", width: 20 },
      { header: "송장번호", key: "invoice_number", width: 30 },
      { header: "결제방법", key: "payment_method", width: 20 },
      { header: "구매자_전화번호", key: "sender_phone", width: 20 },
      { header: "받는분_이름", key: "recipient_name", width: 20 },
      { header: "받는분_주소", key: "recipient_address", width: 20 },
      { header: "받는분_전화번호", key: "recipient_phone", width: 20 },
      { header: "수령위치", key: "receiving_location", width: 20 },
      { header: "공동현관_출입방법", key: "way_to_enter", width: 20 },
      { header: "구매자명", key: "nickname", width: 20 },
      { header: "상품금액", key: "total_price", width: 20 },
      { header: "사용된_소나무_포인트", key: "used_point", width: 20 },
      { header: "상품할인_금액", key: "total_discount", width: 20 },
      { header: "배송비", key: "delivery_fee", width: 20 },
      { header: "실제_결제_금액", key: "total_payment", width: 20 },
      { header: "배송상태", key: "delivery_status", width: 20 },
      { header: "상품_목록", key: "name", width: 20 },
      { header: "상품_수량", key: "quantity", width: 20 },
    ];

    worksheet.addRow({
      order_number: data.order_number,
      invoice_number: data.invoice_number,
      payment_method: data.payment_method,
      sender_phone: data.sender_phone,
      recipient_name: data.recipient_name,
      recipient_address: data.recipient_address,
      recipient_phone: data.recipient_phone,
      way_to_enter: data.way_to_enter,
      nickname: data.nickname,
      receiving_location: data.receiving_location,
      delivery_fee: data.delivery_fee,
      total_price: data.total_price,
      used_point: data.used_point,
      total_payment: data.total_payment,
      total_discount: data.total_discount,
      createdAt: data.createdAt,
      delivery_status: data.delivery_status,
    });
    // worksheet.getColumn("name").values = [
    //   ...data.order_list.map((item) => item.name),
    // ];
    // worksheet.getColumn("quantity").values = [
    //   ...data.order_list.map((item) => item.quantity),
    // ];
    data.order_list.forEach((item) =>
      worksheet.addRow({
        name: item.name,
        quantity: item.quantity,
      })
    );

    // 다운로드
    const mimeType = {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);
    saveAs(blob, `${data.order_number} 주문상세내역.xlsx`);
  };

  //배송완료 API
  const handleDeliveryCompletion = async () => {
    try {
      setLoading(true);
      const response = await LPPAXIOS.put(`/admin/order/status/${id}`, {
        status: "배송완료",
      });
      if (response.status === 200) {
        api.success({
          message: "배송완료되었습니다.",
        });
        setData((prev) => {
          return {
            ...prev,
            delivery_status: "배송완료",
          };
        });
      }
      return;
    } catch (error) {
      api.error({
        message: "해당 주문을 찾지 못했습니다.",
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(
      `/admin/order/order_number?order_number=${id}
    `
    )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          setError(error);
        }
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (error !== null) return <Result status={404} title={"404"} />;

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>주문</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Orders.Main.url()}>주문</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <div className="flex w-full gap-2">
          <Descriptions
            className="w-2/3"
            title={
              <Space className="flex justify-between w-full">
                <Typography.Text>주문 상세 정보</Typography.Text>
                {/* <a
                  href={`${
                    process.env.REACT_APP_ENDPOINT
                  }${"/admin/order/download/{order_number}?order_number="}${id}`}
                  target="_blank"
                > */}
                <Button
                  icon={<DownloadOutlined />}
                  size="small"
                  onClick={handleExcel}
                >
                  주문 상세 정보 다운로드
                </Button>
                {/* </a> */}
              </Space>
            }
            bordered
          >
            <Descriptions.Item label="주문코드">
              {data?.order_number}
            </Descriptions.Item>
            <Descriptions.Item label="구매자명">
              {data?.nickname}
            </Descriptions.Item>
            <Descriptions.Item label="구매자 전화번호">
              {data.sender_phone}
            </Descriptions.Item>
            {/* 받는 사람 이름 필요 */}
            <Descriptions.Item label="받는분 이름">
              {data.recipient_name}
            </Descriptions.Item>
            <Descriptions.Item label="받는분 주소" span={2}>
              {data?.recipient_address}
            </Descriptions.Item>
            <Descriptions.Item label="받는분 전화번호">
              {data.recipient_phone}
            </Descriptions.Item>
            <Descriptions.Item label="공동현관 출입방법" span={2}>
              {data.way_to_enter}
            </Descriptions.Item>
            <Descriptions.Item label="수령위치">
              {data.receiving_location}
            </Descriptions.Item>
            <Descriptions.Item label="배송 상태">
              <Space>
                {data?.delivery_status}
                {(data?.delivery_status === "배송중" ||
                  data?.recipient_name === "녹색교통운동") && (
                  <Button onClick={handleDeliveryCompletion}>배송완료</Button>
                )}
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="결제 방법">
              {data?.payment_method}
            </Descriptions.Item>
            {data?.invoice_number !== 0 && (
              <Descriptions.Item
                label={
                  <div>
                    송장번호 <Button onClick={updateInvoiceNumber}>수정</Button>
                  </div>
                }
              >
                {data?.invoice_number}
                {/* {edit === true ? (
                  <Input
                    defaultValue={data?.invoice_number}
                    maxLength={30}
                    // onKeyPress={onPressOnlyNumber}
                    onKeyPress={handleOnKeyPress}
                    onInput={onInputOnlyNumber}
                    placeholder="송장번호를 입력하세요."
                  />
                ) : (
                  data?.invoice_number
                )} */}
              </Descriptions.Item>
            )}
          </Descriptions>
          <Card className="w-1/3">
            <Space className="flex justify-between w-full">
              <Typography.Text type="secondary">상품 금액</Typography.Text>
              <Typography.Title level={5}>
                +{data.total_price.toLocaleString("Kr")}원
              </Typography.Title>
            </Space>
            <Space className="flex justify-between w-full">
              <Typography.Text type="secondary">상품할인 금액</Typography.Text>
              <Typography.Title level={5}>
                -{data.total_discount.toLocaleString("Kr")}원
              </Typography.Title>
            </Space>

            <Space className="flex justify-between w-full">
              <Typography.Text type="secondary">배송비</Typography.Text>
              <Typography.Title level={5}>
                +{data.delivery_fee.toLocaleString("Kr")}원
              </Typography.Title>
            </Space>
            <Divider />
            <Space className="flex justify-between w-full">
              <Typography.Text>전체 결제 금액</Typography.Text>
              <Typography.Title level={5}>
                +
                {(
                  data.total_price -
                  data.total_discount +
                  data.delivery_fee
                ).toLocaleString("Kr")}
                원
              </Typography.Title>
            </Space>
            <Space className="flex justify-between w-full">
              <Typography.Text type="success">
                사용된 소나무 포인트
              </Typography.Text>
              <Typography.Title level={5} type="success">
                -{data.used_point.toLocaleString("Kr")}원
              </Typography.Title>
            </Space>
            <Divider />
            <Space className="flex justify-between w-full ">
              <Typography.Text>실제 결제 금액</Typography.Text>
              <Typography.Title level={5}>
                {data.total_payment.toLocaleString("Kr")}원
              </Typography.Title>
            </Space>
          </Card>
        </div>
        <Space direction="vertical" className="w-full my-2">
          {data?.order_list.map((item, index) => {
            return (
              <Card key={`order-${index}`}>
                <Space className="flex justify-between">
                  <Image
                    height={90}
                    src={`${process.env.REACT_APP_IMG_ENDPOINT}/${item.thumbnail[0]}`}
                  />
                  <Space direction="vertical" className="w-full">
                    <Typography.Text>{item.name}</Typography.Text>
                    <Divider />
                    <Typography.Text>수량: {item.quantity}</Typography.Text>
                  </Space>
                </Space>
              </Card>
            );
          })}
        </Space>
        <Space>
          {data?.invoice_number === 0 && (
            <Button type="primary" block onClick={postInvoiceNumber}>
              송장번호 추가
            </Button>
          )}
          {/* {data?.invoice_number === 0 &&
            data?.delivery_status === "주문완료" && (
              <Button block onClick={handleCancelOrder} danger>
                주문취소
              </Button>
            )} */}
          <Button block onClick={handleCancelOrder} danger>
            주문취소
          </Button>
        </Space>
        <Modal
          open={open}
          footer={null}
          onCancel={() => {
            setOpen(false);
            form.resetFields();
          }}
        >
          <div>송장번호 추가</div>
          <Form form={form} onFinish={handleAddInvoiceNumber}>
            <Form.Item
              name="invoice_number"
              label="송장번호"
              rules={[
                {
                  required: true,
                  min: 8,
                  message: "송장번호 입력은 필수입니다.",
                },
              ]}
            >
              <Input
                maxLength={30}
                onKeyPress={onPressOnlyNumber}
                onInput={onInputOnlyNumber}
                placeholder="송장번호를 입력하세요."
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" block htmlType="submit">
                송장 추가
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          open={openUpdate}
          footer={null}
          onCancel={() => {
            setOpenUpdate(false);
            form.resetFields();
          }}
        >
          <div>송장번호 수정</div>
          <Form
            form={form}
            onFinish={handleAddInvoiceNumber}
            initialValues={{ invoice_number: data?.invoice_number }}
          >
            <Form.Item
              name="invoice_number"
              label="송장번호"
              rules={[
                {
                  required: true,
                  min: 8,
                  message: "송장번호 입력은 필수입니다.",
                },
              ]}
            >
              <Input
                maxLength={30}
                onInput={onInputOnlyNumber}
                placeholder="송장번호를 입력하세요."
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" block htmlType="submit">
                송장 번호 수정
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </HContent>
    </>
  );
};
