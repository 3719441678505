import { Tag } from "antd";
import { NAVERLOGO } from "../../assets";
export const NaverTag = () => {
  return (
    <Tag color="#2DB400" className="flex items-center gap-2 m-1 w-[75px]">
      <NAVERLOGO />
      Naver
    </Tag>
  );
};
