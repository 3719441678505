import { Header } from "antd/es/layout/layout";
import { theme } from "antd";

interface Props {
  children: React.ReactElement;
}
export default function MDHeader({ children }: Props) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Header
      style={{ background: colorBgContainer }}
      className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
    >
      {children}
    </Header>
  );
}
