import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../LPPUrls";
import { AdminLoginPage } from "../../pages/AdminLoginPage";
import { FindPasswordPage } from "../../pages/FindPasswordPage";
import { LPPAuthCommonLayout } from "../Layout/LPPAuthCommonLayout";

export const LPPAuthRoutes = () => {
  let localData = localStorage.getItem("lppuser");
  if (localData !== null) {
    return <Navigate to={LPPUrls.Admin.Root.pathName} replace />;
  }
  return (
    <LPPAuthCommonLayout>
      <Routes>
        <Route
          path={LPPUrls.Auth.Login.Main.pathName}
          element={<AdminLoginPage />}
        />
        <Route
          path={LPPUrls.Auth.FindPassword.pathName}
          element={<FindPasswordPage />}
        />
        <Route
          path={"*"}
          element={<Navigate to={LPPUrls.Auth.Login.Main.pathName} />}
        />
      </Routes>
    </LPPAuthCommonLayout>
  );
};
