import {
  Layout,
  Typography,
  theme,
  Button,
  Space,
  Divider,
  Avatar,
  Skeleton,
  DatePicker,
  Select,
  Result,
} from "antd";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import useNotification from "antd/es/notification/useNotification";
import { HContent } from "../../../components/common/HContent";

import dayjs from "dayjs";

const { Header } = Layout;
const { Title } = Typography;

interface Ranker {
  userId: number;
  nickName: string;
  profile: string;
  ranking: number;
  rankSize: number;
  co2_reduction: number;
  moving_distance: number;
}

//상품명, 판매가격, 할인가격, 대표이미지, 등록일, 재고, 총판매량, 상태
//상태는 무엇을 의미하나??
interface RESPONSETYPE {
  data: Ranker[];
}

const regionOption = [
  { value: "전체", label: "전체" },
  { value: "서울", label: "서울" },
  { value: "부산", label: "부산" },
  { value: "대구", label: "대구" },
  { value: "인천", label: "인천" },
  { value: "광주", label: "광주" },
  { value: "대전", label: "대전" },
  { value: "울산", label: "울산" },
  { value: "세종", label: "세종" },
  { value: "제주도", label: "제주도" },
  { value: "강원도", label: "강원도" },
  { value: "경기도", label: "경기도" },
  { value: "충청북도", label: "충청북도" },
  { value: "충청남도", label: "충청남도" },
  { value: "전라남도", label: "전라남도" },
  { value: "전라북도", label: "전라북도" },
  { value: "경상남도", label: "경상남도" },
  { value: "경상북도", label: "경상북도" },
];

export const TablePage = () => {
  const navigate = useNavigate();
  //   const location = useLocation();
  //   const params = new URLSearchParams(location.search);
  //   const keyword = params.get("keyword");
  //   const sort = params.get("sort");
  //   const order = params.get("order");

  //const [order, setOrder] = useState(sort !== null ? sort : "createdAt");
  //   const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  //   const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const [api, contextHolder] = useNotification();
  const [data, setData] = useState<Ranker[]>([]);
  const [days, setDays] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  >([dayjs().subtract(7, "d"), dayjs()]);
  const [region, setRegion] = useState("전체");

  const [type, setType] = useState<"co2_reduction" | "moving_distance">(
    "co2_reduction"
  );

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  //   const handleChangeRegion = async (value: number) => {
  //     setLoading(true);
  //     // LPPAXIOS.get();
  //   };

  //   const handleChangeType = async (value: "distance" | "co2") => {
  //     setLoading(true);
  //   };

  const handleNavigate = (id: number) => {
    navigate(LPPUrls.Admin.Users.Detail.url(id));
  };
  const handleOnChangeDaysRange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  ) => {
    if (dates === null) return;
    if (dates[0] !== null && dates[1] !== null) {
      setDays(dates);
    }
    return;
  };

  useEffect(() => {
    if (days !== null && days[0] !== null && days[1] !== null) {
      setLoading(true);
      LPPAXIOS.get<RESPONSETYPE>(
        `/admin/record/ranking?start_date=${days[0].format(
          "YYYY-MM-DD"
        )}&end_date=${days[1].format(
          "YYYY-MM-DD"
        )}&column=${type}&region=${region}`
      )
        .then((res) => {
          setData(res.data.data);
        })
        .catch(function (error) {
          if (error.response.status === 404) {
          }
        })
        .finally(() =>
          setTimeout(() => {
            setLoading(false);
          }, 600)
        );
    }
  }, [api, days, type, region]);

  return (
    <>
      {/* <LoadingSpin loading={loading} /> */}
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>Rank</Title>
      </Header>
      <HContent>
        <Space direction="vertical">
          <Space>
            <DatePicker.RangePicker
              defaultValue={days}
              onChange={handleOnChangeDaysRange}
              //   onOpenChange={() => setDays(null)}
            />
            <Select
              options={regionOption}
              defaultValue={region}
              style={{ width: 120 }}
              onChange={setRegion}
            />
          </Space>
          <Space>
            <Button
              className={`font-semibold border-2 rounded-full min-w-[100px] ${
                type === "co2_reduction" &&
                `border-emerald-700 text-emerald-700`
              }`}
              onClick={() => {
                setType("co2_reduction");
                console.log(region);
              }}
            >
              탄소감축량
            </Button>
            <Button
              className={`font-semibold border-2 rounded-full min-w-[100px] ${
                type === "moving_distance" &&
                `border-emerald-700 text-emerald-700`
              }`}
              onClick={() => setType("moving_distance")}
            >
              이동거리
            </Button>
          </Space>
        </Space>
        <div>
          <div className="flex items-center justify-around"></div>
        </div>
        <div className="flex flex-col items-center justify-center w-full">
          {loading && data.length === 0 && (
            <>
              <RankerComponent
                handleNavigate={handleNavigate}
                id={0}
                rankNumber={1}
                co2_reduction={0}
                moving_distance={0}
                loading={loading}
                profile={""}
                nickName={""}
                type={type}
              />
              <RankerComponent
                handleNavigate={handleNavigate}
                id={0}
                rankNumber={1}
                co2_reduction={0}
                moving_distance={0}
                loading={loading}
                profile={""}
                nickName={""}
                type={type}
              />
              <RankerComponent
                handleNavigate={handleNavigate}
                id={0}
                rankNumber={1}
                co2_reduction={0}
                moving_distance={0}
                loading={loading}
                profile={""}
                nickName={""}
                type={type}
              />
            </>
          )}
          {data.length === 0 && loading === false && (
            <Result title="랭킹이 존재하지 않습니다." />
          )}
          {data.map((item, index) => (
            <RankerComponent
              key={`ranker-${index}`}
              handleNavigate={handleNavigate}
              id={item.userId}
              rankNumber={item.ranking}
              co2_reduction={item.co2_reduction}
              moving_distance={item.moving_distance}
              loading={loading}
              profile={item.profile}
              nickName={item.nickName}
              type={type}
            />
          ))}
        </div>
      </HContent>
      {contextHolder}
    </>
  );
};

const RankerComponent = ({
  id,
  nickName,
  type,
  profile,
  rankNumber,
  co2_reduction,
  moving_distance,
  loading,
  handleNavigate,
}: {
  id: number;
  nickName: string;
  type: "moving_distance" | "co2_reduction";
  profile: string;
  rankNumber: number;
  co2_reduction: number;
  moving_distance: number;
  loading: boolean;
  handleNavigate: (value: number) => void;
}) => {
  return (
    <div className="w-[400px]">
      <Divider />
      <div
        className="flex items-center justify-between w-full hover:bg-slate-50/75 hover:cursor-pointer"
        onClick={() => {
          console.log(id);
          handleNavigate(id);
        }}
      >
        <Space>
          {loading ? (
            <Skeleton.Avatar shape="square" active />
          ) : (
            <Typography.Text strong>{rankNumber}</Typography.Text>
          )}
          {loading ? (
            <Skeleton.Avatar size={30} active />
          ) : (
            <Avatar
              size={30}
              src={`${process.env.REACT_APP_IMG_ENDPOINT}/${profile}`}
            />
          )}
          {loading ? (
            <Skeleton.Input block active />
          ) : (
            <Typography.Text>{nickName}</Typography.Text>
          )}
        </Space>
        <span>
          {loading ? (
            <Skeleton.Avatar shape="square" active />
          ) : (
            <Typography.Text type="success" strong className="text-lg">
              {type === "co2_reduction"
                ? co2_reduction.toLocaleString("Kr")
                : moving_distance.toLocaleString("Kr")}
            </Typography.Text>
          )}
          {loading ? (
            <Skeleton.Avatar shape="square" active />
          ) : (
            <Typography.Text type="secondary">
              {type === "co2_reduction" ? "kg" : "km"}
            </Typography.Text>
          )}
        </span>
      </div>
    </div>
  );
};

// const TopRankerComponent = ({
//   nickName,
//   type,
//   profile,
//   rankNumber,
//   rankSize,
//   loading,
// }: {
//   nickName: string;
//   type: "distance" | "co2";
//   profile: string;
//   rankNumber: number;
//   rankSize: number;
//   loading: boolean;
// }) => {
//   return (
//     <div className="w-[400px] h-[150px] rounded-lg  border-2 border-emerald-700">
//       <Space>
//         <Avatar
//           size={40}
//           src={process.env.REACT_APP_IMG_ENDPOINT + "/" + profile}
//         />
//         <span>
//           <Typography.Text strong type="success">
//             {rankSize}
//           </Typography.Text>
//           <Typography.Text type="secondary">
//             {type === "co2" ? "kg" : "km"}
//           </Typography.Text>
//         </span>
//       </Space>
//       <Typography.Text>{nickName}</Typography.Text>
//     </div>
//   );
// };
