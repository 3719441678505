import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Divider,
  Image,
  Space,
  Button,
  Popconfirm,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useCallback, useState } from "react";

import { HContent } from "../../../components/common/HContent";

import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";

import { timeConverter } from "../../../utils/timeConverter";
import { LPPAXIOS } from "../../../framework/api/core.api";
import useNotification from "antd/es/notification/useNotification";
import { LoadingSpin } from "../../../components/common/Loading";

const { Header } = Layout;
const { Title } = Typography;

export const DetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = useNotification();
  const defaultProps = {
    lat: Number(location.state.latitude),
    lng: Number(location.state.longitude),
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP}`,
  });
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const onLoad = useCallback(function callback(map: google.maps.Map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(defaultProps);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // 명진님이 API 만들어주면 됨.
  const handleDeleteReport = async () => {
    setLoading(true);

    LPPAXIOS.delete(`/admin/report/${id}`)
      .then(() => {
        api.success({
          message: "신고 기록을 삭제하였습니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Report.Main.url(), { replace: true });
        }, 600);
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          api.error({
            message: "400 Error",
          });
        } else if (error.response.status === 404) {
          api.error({
            message: "해당 기록을 찾을 수 없습니다.",
            description: `${error.response.status} Error`,
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>신고 상세 페이지</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Report.Main.url()}>신고</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>신고 상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Typography>
          <Space className="flex justify-between">
            <Space direction="vertical">
              <Typography.Text>{location.state.nickName}</Typography.Text>
              <Typography.Text>
                {timeConverter(location.state.createdAt)}
              </Typography.Text>
            </Space>
            <Popconfirm
              title="기록 삭제"
              description="해당 기록을 삭제하시겠습니까?"
              onConfirm={handleDeleteReport}
              okText="예"
              cancelText="아니오"
            >
              <Button type="default">기록 삭제</Button>
            </Popconfirm>
          </Space>
          <Typography.Title level={4}>
            {location.state.content}
          </Typography.Title>
          <Divider />
        </Typography>
        <Space size="large" className="flex items-start">
          <Image
            className="min-w-[300px] max-w-[300px]"
            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${location.state.picture}`}
          />
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={{ height: "500px", width: "500px" }}
              center={defaultProps}
              zoom={12}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              <MarkerF
                position={{
                  lat: Number(location.state.latitude),
                  lng: Number(location.state.longitude),
                }}
              />
            </GoogleMap>
          ) : (
            <div style={{ width: "100%", height: "500px" }}>
              GoogleMap load...
            </div>
          )}
        </Space>
      </HContent>
    </>
  );
};
