import {
  Layout,
  Typography,
  theme,
  Table,
  Input,
  notification,
  Pagination,
  PaginationProps,
  DatePicker,
} from "antd";

import { ColumnsType } from "antd/es/table";

import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import dayjs, { type Dayjs } from "dayjs";

import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import {
  filterNonEmptyProperties,
  paramsFilter,
} from "../../../utils/ParamsFilter";

const { Header, Content } = Layout;
const { Title } = Typography;

interface ExpirationPoint {
  id: number;
  phone: string;
  nickName: string;
  email: string;
  point: number;
}
//회원이름(닉네임), 이메일, 가입경로(구글, 페이스북), 탄소감축량, 가입일, 포인트
export interface GETUSERLISTTYPE {
  data: ExpirationPoint[];
  total: number;
}
const PERPAGE_CONSTANT = 10;
export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const expiredDate =
    params.get("expiredDate") ?? dayjs(new Date()).format("YYYY-MM");

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    Number(params.get("start") ?? 1)
  );
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<ExpirationPoint[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const userListColumn: ColumnsType<ExpirationPoint> = [
    {
      title: "닉네임",
      dataIndex: "nickName",
      // sorter: true,
      // defaultSortOrder:
      //   sort === "nickName" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "이메일",
      dataIndex: "email",
      // sorter: true,
      // defaultSortOrder:
      //   sort === "email" ? (order === "ASC" ? "ascend" : "descend") : null,
    },

    {
      title: "연락처",
      dataIndex: "phone",
      width: 150,
      ellipsis: true,
      // sorter: true,
      // defaultSortOrder:
      //   sort === "phone" ? (order === "ASC" ? "ascend" : "descend") : null,
    },

    {
      title: "소멸 포인트",
      dataIndex: "point",
      // sorter: true,
      // defaultSortOrder:
      //   sort === "amount" ? (order === "ASC" ? "ascend" : "descend") : null,
      render: (_, record) => record.point.toLocaleString(),
    },

    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => (
        <Link
          to={LPPUrls.Admin.Users.Detail.url(record.id)}
          className="font-medium "
        >
          상세 페이지
        </Link>
      ),
    },
  ];

  const handleCalendar = (value: Dayjs | null) => {
    if (value) {
      const valueString = dayjs(value, "YYYY-MM").format("YYYY-MM");
      console.log(valueString);
      navigate(
        LPPUrls.Admin.ExpiredPoint.Main.url2(
          paramsFilter({
            search: search,
            expiredDate: valueString,
            sort: sort,
            order: order,
            start: 1,
            perPage: PERPAGE_CONSTANT,
          })
        )
      );
      setCurrentPage(1);
    } else return;
  };

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.ExpiredPoint.Main.url2(
        paramsFilter({
          search: value,
          expiredDate: expiredDate,
          sort: sort,
          order: order,
          start: 1,
          perPage: PERPAGE_CONSTANT,
        })
      )
    );
    setCurrentPage(1);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.ExpiredPoint.Main.url2(
        paramsFilter({
          search: search,
          expiredDate: expiredDate,
          sort: sort,
          order: order,
          start: page,
          perPage: PERPAGE_CONSTANT,
        })
      )
    );
    setCurrentPage(page);
  };

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter.field ?? null;
    let result = filterNonEmptyProperties(filter);
    console.log(result);
    handleChangeSort(sort, order, result);
  };
  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    result: { [key: string]: string | null }
  ) => {
    console.log(result.recommend);
    navigate(
      LPPUrls.Admin.ExpiredPoint.Main.url2(
        paramsFilter({
          search: search,
          sort: sort,
          order: order,
          expiredDate: expiredDate,
          start: currentPage,
          perPage: PERPAGE_CONSTANT,
          ...result,
        })
      )
    );
    setCurrentPage(currentPage);
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<GETUSERLISTTYPE>(
      `/admin/point/expiration` +
        paramsFilter({
          search: search,
          sort: sort,
          order: order,
          expiredDate: expiredDate,
          start: currentPage,
          perPage: PERPAGE_CONSTANT,
        })
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [currentPage, api, search, order, sort, expiredDate]);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>소멸 포인트 관리</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={userListColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <DatePicker
                picker="month"
                defaultValue={dayjs(expiredDate)}
                onChange={(e) => handleCalendar(e)}
              />
              <Input.Search
                defaultValue={search ?? ""}
                style={{ width: 300 }}
                placeholder="닉네임|이메일|연락처를 검색하세요"
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              />
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={PERPAGE_CONSTANT}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
};
