import { Tag } from "antd";
import { FacebookOutlined } from "@ant-design/icons";

export const FaceBookTag = () => {
  return (
    <Tag color="#3B5999" className="m-1 w-[75px]" icon={<FacebookOutlined />}>
      facebook
    </Tag>
  );
};
