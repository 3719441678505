import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Divider,
  notification,
  Space,
  Typography,
} from "antd";

import { Layout } from "antd";
import { LPPUrls } from "../../../LPPUrls";
import { useNavigate } from "react-router-dom";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LoadingSpin } from "../../../components/common/Loading";
import { AdminTag } from "../../../components/common/AdminTag";
import { StaffTag } from "../../../components/common/StaffTag";
import axios from "axios";

enum LevelType {
  ADMIN = "admin",
  STAFF = "staff",
}

interface GETAUTHTYPE {
  id: number;
  name: string;
  role: LevelType;
  status: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  enabled: boolean;
  type: LevelType;
}

export const MyPageRoot = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GETAUTHTYPE | null>(null);
  const [api, contetxtHolder] = notification.useNotification();

  const handleLogout = async () => {
    try {
      const res = await LPPAXIOS.delete("/admin/auth");
      if (res.status === 204) {
        localStorage.removeItem("lppuser");
        return navigate(LPPUrls.Auth.Root.pathName, { replace: true });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: `Notification ${error.code}`,
          description: `${error.message}`,
        });
      }
    }
  };
  // const handleOnClickChangePassword = () => {};

  // const onClickFixName = () => {};
  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<GETAUTHTYPE>(`/admin/auth`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {contetxtHolder}
      <LoadingSpin loading={loading} />
      <Layout.Content className="h-screen md:flex">
        <aside className="flex items-center justify-between w-full gap-3 p-3 bg-white h-13 md:w-3/12 md:h-full drop-shadow-xl md:flex-col md:justify-start">
          <header className="hidden w-full md:block">Rapopoo</header>
          <Avatar size={128} />
          <div className="flex flex-col items-center">
            <h2>{data?.name}</h2>
            <p>{data?.name}</p>
            {data?.role === LevelType.ADMIN && <AdminTag />}
            {data?.role === LevelType.STAFF && <StaffTag />}
          </div>
          <footer>
            <Button type="primary" htmlType="submit" onClick={handleLogout}>
              Log Out
            </Button>
          </footer>
        </aside>
        <main className="flex flex-col h-full gap-4 p-3 md:w-9/12">
          <Card
            bordered
            className="border-indigo-400 border-solid border-1 drop-shadow-md "
          >
            <Typography.Text strong type="secondary">
              기본정보
              {/* <Tooltip
                title={<div>이름, 전화번호를 변경하실 수 있습니다.</div>}
              >
                <ExclamationCircleOutlined />
              </Tooltip> */}
            </Typography.Text>

            <div className="flex items-end justify-between">
              <Space>
                <Avatar size={64} />
                <Space direction="vertical">
                  <span>
                    <Typography.Text strong>{data?.name}</Typography.Text>
                    {data?.role === LevelType.ADMIN && <AdminTag />}
                    {data?.role === LevelType.STAFF && <StaffTag />}
                  </span>
                  <Typography.Text type="secondary">
                    {data?.name}
                  </Typography.Text>
                </Space>
              </Space>
              {/* <Button onClick={onClickFixName}>이름 수정</Button> */}
            </div>
            <Divider />
            <div className="flex items-end justify-between">
              {/* <span className="flex gap-4">
                <MailOutlined />
                <Typography.Text strong>{data?.name}</Typography.Text>
              </span> */}
              {/* <Button disabled>수정</Button> */}
            </div>
            <Divider />
            <div className="flex items-end justify-between">
              {/* <span className="flex gap-4">
                <PhoneOutlined />
                <Typography.Text strong>{data?.name}</Typography.Text>
              </span> */}
              {/* <Button>수정</Button> */}
            </div>
          </Card>
          {/* <Card bordered className="flex justify-end drop-shadow-md ">
            <Space
              onClick={handleOnClickChangePassword}
              className="cursor-pointer"
            >
              <Typography.Text strong>비밀번호 변경</Typography.Text>
              <RightOutlined />
            </Space>
          </Card> */}
        </main>
      </Layout.Content>
    </>
  );
};
