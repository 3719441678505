import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../LPPUrls";
import NaverLoginPage from "../../pages/AppPages/NaverLoginPage";
import RedirectPage from "../../pages/AppPages/RedirectPage";

export const LPPAppRoutes = () => {
  return (
    <Routes>
      <Route
        path={LPPUrls.App.Login.Naver.pathName}
        element={<NaverLoginPage />}
      />
      <Route path={LPPUrls.App.Redirect.pathName} element={<RedirectPage />} />
      <Route
        path={"*"}
        element={<Navigate to={LPPUrls.App.Login.Naver.pathName} />}
      />
    </Routes>
  );
};
