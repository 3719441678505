import { Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

interface IWithTooltipInfo {
  text: React.ReactNode;
  title: React.ReactNode;
}
export const WithTooltipInfo = ({ text, title }: IWithTooltipInfo) => {
  return (
    <span className="flex items-center">
      {text}
      <Tooltip title={title ?? ""}>
        <ExclamationCircleOutlined />
      </Tooltip>
    </span>
  );
};
