import { Button, Card, Form, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { LPPUrls } from "../LPPUrls";
import React, { useState } from "react";

export const FindPasswordPage = () => {
  const onFinish = () => {};
  const [email1, setEmail1] = useState<string>("");
  const [email2, setEmail2] = useState<string>("");

  const handleChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail1(e.target.value);
  };
  const handleChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail2(e.target.value);
  };

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="w-fit h-fit px-1 py-2">
        <Card title={"LAPOPOO Password Recovery"}>
          <Form
            layout="vertical"
            name="normal_login"
            className="w-[300px]"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              label={"Email"}
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                prefix={<MailOutlined />}
                placeholder="Email"
                onChange={handleChange1}
              />
            </Form.Item>
            <Form.Item
              label={"Confirmation Email"}
              name="emailconfirm"
              rules={[
                {
                  required: true,
                  message: "Please input your confirmation email!",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                placeholder="Confirmation Email"
                onChange={handleChange2}
              />
            </Form.Item>
            <Form.Item>
              <Link
                className="float-right text-blue-400"
                to={LPPUrls.Auth.Login.Main.url}
              >
                Back to login page
              </Link>
            </Form.Item>

            <Form.Item>
              {email1 === email2 ? (
                <Button type="primary" htmlType="submit" block>
                  Find Password
                </Button>
              ) : (
                <Button disabled type="primary" htmlType="submit" block>
                  Find Password
                </Button>
              )}
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};
