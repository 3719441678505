import { useEffect } from "react";

function NaverLoginPage() {
  const naverClientId = process.env.REACT_APP_NAVER_CLIENT_ID;
  const redirectUrl = window.location.origin + "/app/redirect";

  useEffect(() => {
    // 재인증 auth_type=reauthenticate
    window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${naverClientId}&redirect_uri=${redirectUrl}&state=STATE_STRING&auth_type=reauthenticate`;
  }, [naverClientId, redirectUrl]);

  return (
    <div>
      <h1>네이버 로그인 페이지</h1>
      <p>네이버 로그인 페이지로 이동 중...</p>
    </div>
  );
}
export default NaverLoginPage;
//https://admin.thegreentransport.net/app/naverLogin
