import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import { CreatePage } from "./Create";
// import { DetailPage } from "./Detail";
import { TablePage } from "./Table";
import { UpdatePage } from "./Update";

export const QnAsRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />
      {/* <Route
        path={LPPUrls.Admin.QnA.Detail.pathName}
        element={<DetailPage />}
      /> */}
      <Route
        path={LPPUrls.Admin.QnA.Create.pathName}
        element={<CreatePage />}
      />
      <Route
        path={LPPUrls.Admin.QnA.Update.pathName}
        element={<UpdatePage />}
      />
      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};
