import { Navigate, Route, Routes } from "react-router-dom";
// import { LPPUrls } from "../../../LPPUrls";

import { TablePage } from "./Table";

export const RankRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />

      {/* <Route
        path={LPPUrls.Admin.Record.Create.pathName}
        element={<CreatePage />}
      /> */}
      {/* <Route
        path={LPPUrls.Admin.Record.Update.pathName}
        element={<UpdatePage />}
      /> */}
      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};
