import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Result,
  Divider,
  Image,
  Tag,
  Space,
  Popconfirm,
  Button,
  Table,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LoadingSpin } from "../../../components/common/Loading";
import { HContent } from "../../../components/common/HContent";
import useNotification from "antd/es/notification/useNotification";
import { ColumnsType } from "antd/es/table";

const { Header } = Layout;
const { Title } = Typography;
const urlRegex = /(https?:\/\/[^\s]+)/;

interface RESPONSETYPE {
  data: Challenge;
  users: User[];
}
interface Challenge {
  id: number;
  topic: string;
  content: any[];
  image: string[];
  startDate: string;
  endDate: string;
  participant: number;
  createdAt: string;
  views: number;
}

interface User {
  id: number;
  nickName: string;
  email: string;
  phone: string;
  createdAt: string;
  type: string;
  age: number;
  moving_distance: number;
  co2_reduction: number;
  planted_tree: number;
}

export const DetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Challenge>();
  const [user, setUser] = useState<User[]>([]);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const userColumn: ColumnsType<User> = [
    {
      title: "닉네임",
      dataIndex: "nickName",
      sorter: (a, b) => a.nickName.length - b.nickName.length,
    },
    // { title: "이메일", dataIndex: "email" },
    { title: "연령", dataIndex: "age", sorter: (a, b) => a.age - b.age },
    {
      title: "이동 거리",
      dataIndex: "moving_distance",
      sorter: (a, b) => a.moving_distance - b.moving_distance,
    },
    {
      title: "탄소 감축량",
      dataIndex: "co2_reduction",
      sorter: (a, b) => a.co2_reduction - b.co2_reduction,
    },
    {
      title: "심은 소나무",
      dataIndex: "planted_tree",
      sorter: (a, b) => a.planted_tree - b.planted_tree,
    },
    {
      title: (
        <a
          href={`${
            process.env.REACT_APP_ENDPOINT
          }${`/admin/challenge/${id}/download`}`}
          target="_blank"
        >
          <Button icon={<DownloadOutlined />} size="small" onClick={() => {}}>
            챌린지 참가자 명단 다운로드
          </Button>
        </a>
      ),
      dataIndex: "operation",
      render: (_, record) => (
        <Link
          to={LPPUrls.Admin.Challenge.User.url(record.id)}
          className="font-medium "
          state={{ startDay: data?.startDate, endDay: data?.endDate }}
        >
          상세 페이지
        </Link>
      ),
    },
  ];

  const handleDeleteChallenge = async () => {
    setLoading(true);
    LPPAXIOS.delete(`/admin/challenge/${id}`)
      .then((resolve) => {
        api.success({
          message: "챌린지를 정상적으로 삭제하였습니다.",
        });
        setTimeout(() => navigate(LPPUrls.Admin.Challenge.Main.url()), 500);
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          api.error({
            message: "잘못된 요청입니다.",
          });
        }
      });
  };
  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(`/admin/challenge/${id}`)
      .then((res) => {
        setData(res.data.data);
        setUser(res.data.users);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          setError(error);
        }
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (error !== null) return <Result status={404} title={"404"} />;

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>챌린지</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Challenge.Main.url()}>챌린지</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>챌린지 상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Typography>
          <Typography.Title level={4}>주제: {data?.topic}</Typography.Title>
          <Space className="flex justify-between">
            <Space>
              <Typography.Text>
                기간: <Tag>{data?.startDate}</Tag>~ <Tag>{data?.endDate}</Tag>
              </Typography.Text>
              |<Typography.Text>조회수: {data?.views}</Typography.Text>
            </Space>
            <Space size="small">
              <Button
                type="text"
                onClick={() =>
                  navigate(LPPUrls.Admin.Challenge.Update.url(id!), {
                    state: data,
                  })
                }
              >
                수정
              </Button>
              {/* |
              <Popconfirm
                title="챌린지 삭제"
                description="챌린지를 삭제하시겠습니까?"
                onConfirm={handleDeleteChallenge}
                okText="예"
                cancelText="아니오"
              >
                <Button type="text">삭제</Button>
              </Popconfirm> */}
            </Space>
          </Space>
          {/* <Dropdown menu={{ items }} placement="bottomRight">
              <MoreOutlined
                className="cursor-pointer"
                style={{ fontSize: "18px" }}
              />
            </Dropdown>
          </Space> */}
          <Divider />
          <Image
            width={300}
            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.image[0]}`}
          />

          <Typography>
            {data?.content.map((item, index) => {
              if (item.type === "paragraph") {
                if (urlRegex.test(item.data.text)) {
                  return (
                    <a
                      key={`content-${index}`}
                      href={item.data.text}
                      target="_blank"
                    >
                      {item.data.text}
                    </a>
                  );
                } else {
                  if (item.data.text === "<br>") {
                    return <br key={index} />;
                  } else {
                    return (
                      <Typography.Paragraph
                        key={`content-${index}`}
                        className="whitespace-pre-line"
                      >
                        {item.data.text}
                      </Typography.Paragraph>
                    );
                  }
                }
              } else if (item.type === "linkTool") {
                return (
                  <a
                    key={`content-${index}`}
                    href={item.data.link}
                    target="_blank"
                  >
                    {item.data.link}
                  </a>
                );
              }
              return null;
            })}
          </Typography>
        </Typography>
        <Divider />

        <Table
          dataSource={user}
          rowKey={(record) => record.id}
          columns={userColumn}
          title={() => <Title level={4}>참가자 명단</Title>}
        />
      </HContent>
    </>
  );
};
