import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  notification,
  Descriptions,
  Card,
  Form,
  Modal,
  Radio,
  Button,
  Divider,
  Space,
  Popconfirm,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios from "axios";
import { LoadingSpin } from "../../../components/common/Loading";
import { timeConverter } from "../../../utils/timeConverter";

interface RESPONSETYPE {
  id: number;
  name: string;
  role: string;
  status: string;
  createdAt: string;
  userId: number;
}

enum ACTIVETYPE {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
enum LevelType {
  ADMIN = "admin",
  STAFF = "staff",
}

const LvOption = [
  { label: "관리자", value: LevelType.ADMIN },
  { label: "스태프", value: LevelType.STAFF },
];

const StatusOption = [
  { label: "활성", value: ACTIVETYPE.ACTIVE },
  { label: "비활성", value: ACTIVETYPE.INACTIVE },
];
const { Header, Content } = Layout;
const { Title } = Typography;

export const DetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RESPONSETYPE>();
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const localData = localStorage.getItem("lppuser");

  const [form] = Form.useForm();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleOpenModal = () => {
    setOpen(true);
  };

  const onFinish = async (value: any) => {
    try {
      setLoading(true);
      value = { ...value };

      const res = await LPPAXIOS.put(`/admin/administrators/${id}`, value);

      if (res.status === 201) {
        api.success({
          message: "",
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: "",
        });
      }
    } finally {
      setLoading(false);
      setOpen(false);
      window.location.reload();
    }
  };

  const handleDeleteAdmin = async () => {
    // try {
    //   setLoading(true);
    //   const res = await LPPAXIOS.delete(
    //     `/admin/administrators/${123123123123}`
    //   );
    //   console.log(res.status);
    // } catch (error) {
    //   if (axios.isAxiosError(error)) {
    //     console.log(error.response.status);
    //   }
    // } finally {
    //   setLoading(false);
    // }
    setLoading(true);
    await LPPAXIOS.delete(`/admin/administrators/${id}`)
      .then(() => {
        api.success({
          message: "관리자 권한을 삭제하였습니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.adminAccount.Main.url(), { replace: true });
        }, 600);
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          api.error({
            message: "해당 관리자를 찾을 수 없습니다.",
            description: `${error.response.status} Error`,
          });
        } else if (error.response.status === 409) {
          api.error({
            message: "Admin 계정은 삭제할 수 없습니다.",
            description: `${error.response.status} Error`,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(`/admin/administrators/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: "관리자 정보 조회를 실패하였습니다.",
            description: error.response?.data.message,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [id, api]);

  if (localData === null) {
    return <Navigate to={LPPUrls.Auth.Root.pathName} replace />;
  }

  const isAdmin = JSON.parse(localData).role === "admin" ? true : false;

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>Admin Account</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.adminAccount.Main.url()}>
              Admin Account
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>관리자 상세조회</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content className="p-2 m-6 bg-white">
        <Descriptions bordered>
          <Descriptions.Item label="이름">{data?.name}</Descriptions.Item>
          <Descriptions.Item label="권한">{data?.role}</Descriptions.Item>
          <Descriptions.Item label="상태">{data?.status}</Descriptions.Item>
          <Descriptions.Item label="생성일">
            {timeConverter(data?.createdAt ?? "")}
          </Descriptions.Item>
        </Descriptions>
        <Card
          className="mt-2 cursor-pointer drop-shadow-md hover:drop-shadow-lg"
          onClick={handleOpenModal}
        >
          관리자 권한 수정
        </Card>
        {isAdmin && (
          <Space className="justify-end w-full py-3">
            <Popconfirm
              title="관리자 삭제"
              description="해당 관리자를 삭제하시겠습니까?"
              onConfirm={handleDeleteAdmin}
              okText="예"
              cancelText="아니오"
            >
              <Button type="default" danger>
                관리자 삭제
              </Button>
            </Popconfirm>
          </Space>
        )}
        <Modal
          centered
          forceRender
          open={open}
          title={"관리자 추가"}
          footer={null}
          onCancel={() => {
            setOpen(false);
            form.resetFields();
          }}
          width={"50%"}
        >
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              role: LevelType.STAFF,
              status: ACTIVETYPE.INACTIVE,
            }}
          >
            <Form.Item label="권한" name="role">
              <Radio.Group options={LvOption} />
            </Form.Item>

            <Form.Item label="상태" name="status">
              <Radio.Group options={StatusOption} />
            </Form.Item>

            <Divider />
            <Form.Item>
              <div className="flex justify-end gap-2">
                <Button
                  onClick={() => {
                    setOpen(false);
                    form.resetFields();
                  }}
                >
                  cancel
                </Button>
                <Button htmlType="submit" type="primary" loading={loading}>
                  ok
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </Content>
    </>
  );
};
